import { render, staticRenderFns } from "./PigeonRacingPlatform2D.vue?vue&type=template&id=6a9b1f54&scoped=true"
import script from "./PigeonRacingPlatform2D.vue?vue&type=script&lang=js"
export * from "./PigeonRacingPlatform2D.vue?vue&type=script&lang=js"
import style0 from "./PigeonRacingPlatform2D.vue?vue&type=style&index=0&id=6a9b1f54&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a9b1f54",
  null
  
)

export default component.exports