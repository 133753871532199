<template>
    <div style="height: 100%;">
        <!-- ˙直播平台页面 -->

        <!--  style="height: 90%;" 动画节点  v-if="colseload == 0" -->
        <div id="loader-wrapper" v-if="colseload == 0">
            <div id="loader"></div>
            <div class="loader-section section-left"></div>
            <div class="loader-section section-right"></div>
            <!--正在加载,请耐心等待 -->
            <div class="load_title">{{$t('phoneLanguage.loading')}}
                <br>
                <!-- <span>V0.1</span> -->
            </div>
        </div>


        <!-- cesium 地图组件 123{{  list }} -->
        <div id="cesiumContainer" style="height: 100%; width: 100%;"></div>


        <!-- 梯队数据-->
        <div v-if="isEchelonVisible">

            <div class="echelonBox on" data-v-e6b6cb14="" @mouseover="isHovered = true" @mouseleave="isHovered = false"
                :style="{
                    backgroundColor: isHovered ? '#23222278' : 'rgba(255, 255, 255, 0.1)'
                }">
                <div class="closePlay2 main_flex_ar" :style="{
                    opacity: isHovered ? 1 : 0,
                    backgroundColor: isHovered ? '#ffffff66' : 'rgba(255, 255, 255, 0.1)'
                }">
                    <i class="el-icon" @click="closeEchelon">
                        <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                            <path fill="currentColor"
                                d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z">
                            </path>
                        </svg>
                    </i>
                </div>
                <div class="infoBoxTit main_flex">
                    <!-- 梯队数据 -->
                    <div class="titBoxImg">
                        <img style="width: 30px;height: 30px;margin-right: 15px" src="/mapGLB/tier.png" alt="梯队数据">
                    </div>{{$t('computerLanguage.teamData')}}
                </div>
                <div class="homingTime">
                    <div style="margin-top: 5px;" id="currentTime_time">{{ EchelonTime }}</div>
                </div>
                <div class="echelonCont">
                    <div class="echelonItem main_flex_be">
                        <div class="echelonTit">0-20 {{$t('computerLanguage.kilometer')}}</div>
                        <div class="echelonNum">{{ echelonData_0_20 }}% </div>
                    </div>
                    <div class="echelonItem main_flex_be">
                        <div class="echelonTit">20-35 {{$t('computerLanguage.kilometer')}}</div>
                        <div class="echelonNum">{{ echelonData_20_35 }}% </div>
                    </div>
                    <div class="echelonItem main_flex_be">
                        <div class="echelonTit">35-50 {{$t('computerLanguage.kilometer')}}</div>
                        <div class="echelonNum">{{ echelonData_35_50 }}% </div>
                    </div>
                    <div class="echelonItem main_flex_be">
                        <div class="echelonTit">50-65 {{$t('computerLanguage.kilometer')}}</div>
                        <div class="echelonNum">{{ echelonData_50_65 }}%</div>
                    </div>
                    <div class="echelonItem main_flex_be">
                        <div class="echelonTit">65-80 {{$t('computerLanguage.kilometer')}}</div>
                        <div class="echelonNum">{{ echelonData_65_80 }}%</div>
                    </div>
                    <div class="echelonItem main_flex_be">
                        <div class="echelonTit">80 {{$t('computerLanguage.aboveKilometer')}}</div>
                        <div class="echelonNum">{{ echelonData_80 }}%</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 排名数据 -->
        <div v-if="isShowRanking">
            <div class="echelonBox on" data-v-e6b6cb14="" @mouseover="isShowRankingHovered = true"
                @mouseleave="isShowRankingHovered = false" :style="{
                    backgroundColor: isShowRankingHovered ? '#23222278' : 'rgba(255, 255, 255, 0.1)'
                }">
                <div class="closePlay2 main_flex_ar" :style="{
                    opacity: isShowRankingHovered ? 1 : 0,
                    backgroundColor: isShowRankingHovered ? '#ffffff66' : 'rgba(255, 255, 255, 0.1)'
                }">
                    <i class="el-icon" @click="closeRanking">
                        <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                            <path fill="currentColor"
                                d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z">
                            </path>
                        </svg>
                    </i>
                </div>
                <div class="infoBoxTit main_flex">
                    <!-- 赛鸽排名 -->
                    <div class="titBoxImg">
                        <img style="width: 30px;height: 30px;margin-right: 15px" src="/mapGLB/tier.png" alt="赛鸽排名">
                    </div>{{$t('computerLanguage.pigeonRanking')}}
                </div>
                <div class="homingTime">
                    <div style="margin-top: 5px;" id="currentTime_time">{{ EchelonTime }}</div>
                </div>

                <!-- 表格排名数据 -->

                <!-- <el-table
					:data="tableData"
                    height="250" border style="width: 100%"
						>
					<el-table-column label="排名" type="index" width="57px">
						<template scope="scope">
							<span class="index_common" v-bind:class="[scope.$index + 1 == '1' ? 'index_one' : scope.$index + 1 == '2' ? 'index_two' : scope.$index + 1 == '3' ? 'index_three' : '']">
								{{ scope.$index + 1 }}
							</span>
						</template>
</el-table-column>
<el-table-column label="时间" prop="gpsTime">
</el-table-column>
<el-table-column prop="accumulateDuration" label="累积时间">
</el-table-column>
</el-table> -->

                <el-table :data="sortedTableData"
                    :header-cell-style="{ color: 'rgba(255,255,255,1)', textAlign: 'center' }"
                    :cell-style="{ textAlign: 'center' }" height="558" border style="width: 100%">

                    <!-- 排名 -->
                    <el-table-column :label="$t('computerLanguage.ranking')" width="60">
                        <template slot-scope="scope">
                            <div style="display: flex; justify-content: center;">
                                <div
                                    v-bind:class="[scope.$index + 1 == '1' ? 'index_one' : scope.$index + 1 == '2' ? 'index_two' : scope.$index + 1 == '3' ? 'index_three' : '']">
                                    {{ scope.$index + 1 }}</div>
                                <div style="display: flex; align-items: center;">
                                    <img class="index_common"
                                        v-bind:src="[scope.$index + 1 == '1' ? '/mapGLB/gold.png' : scope.$index + 1 == '2' ? '/mapGLB/silver.png' : scope.$index + 1 == '3' ? '/mapGLB/copper.png' : 'none']"
                                        v-if="scope.$index + 1 <= 3">
                                </div>
                            </div>
                        </template>
                    </el-table-column>

                    <!-- 鸽环号 -->
                    <el-table-column :label="$t('computerLanguage.pigeonRingNumber')" prop="deviceImei">
                    </el-table-column>
                     <!-- 昵称 -->
                    <el-table-column :label="$t('computerLanguage.nickname')" prop="deviceName">
                    </el-table-column>
                    <!-- 离终点（km） -->
                    <el-table-column :label="$t('computerLanguage.distanceToDestination_km')" prop="endDistance">
                    </el-table-column>

                </el-table>


            </div>
        </div>

        <div id="test" class="botOtherBox" v-if="true">
            <!-- 切换 -->
            <div class="botOtherItem" @click="switchAnimation()">{{$t('computerLanguage.switch')}}</div>
            <!-- 播放 -->
            <div class="botOtherItem"  @click="startAnimation()">{{$t('computerLanguage.play')}}</div>

            <!-- 暂停 -->
            <div class="botOtherItem" @click="pauseAnimation()">{{$t('computerLanguage.pause')}}</div>

            <!-- 重置 -->
            <div class="botOtherItem"  @click="resetAnimation()">{{$t('computerLanguage.reset')}}</div>

            <!-- 加速 -->
            <div class="botOtherItem"  @click="speedUpAnimation()">{{$t('computerLanguage.accelerate')}}</div>

            <!-- 减速 -->
            <div class="botOtherItem"  @click="speedDownAnimation()">{{$t('computerLanguage.decelerate')}}</div>

            <!-- 数据 -->
            <div class="playBox main_flex_ar">

                <div class="playBoxItem main_flex_be" @mouseover="showBox" @mouseleave="hideBox">
                    <div class="imgBox" @mouseover="showBox">
                        <img v-if="!isShowIoc"
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD4AAAA+CAMAAABEH1h2AAAAq1BMVEUAAAD////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////Nr6iZAAAAOHRSTlMA8Yztth26zp1m4xNN9CJzLcTe+kbB6MoP0bSKMQrilpFhShanBK2YhW5pPxnafncqBtZTOCZaeo9UJeIAAALqSURBVEjH7ZbpkqIwEICNQYjcmHCKcooc3mfe/8k2OEyNos7i1Fbt7NZ8Pyyqu750hBzd+9skx/163H+J8Xp/TBpdJ2eUedwLeBk6E73RtdAW4YuIdqg1empjShXQHUWhFNtpoxsWpdA1OY7vBMeZLqTUMhpdgBQYgTbsjBYYgEKh0V2FWmnkDzrjR6lFFfe9OqDTeez0OuPE8ykFwrVOJt31CWnpIpKl7rokI/FH/5f0RFWlbRddUjf6MbkNHauoKGT19/pWjYu8f96rV7FBfE4N10Vk9rkuqb5cBPxuaaOh/raUZ4sNKettRqlw+lSfqUQLeHcKMFVE/qxfJrM55Zk9UijDlJ9P3lH9uAhMizYoZqEvBrqspTtMGdaOX/lPdakq5p4wAhfVspgB+fwUjTnXutijbLXXnYd6LG0XJOffCsOlnQXIZuMAO82WlIFF1wsjf/LkuxPHqXIm1Ey5cVT5ZGUqbP7WpbLiIi3WB8x+qscpZOVGtheWVcIOhcPKeBtOGdlZvj/Oeoyn+uTQX2KLD0viD5w67hwu9Sk02Hib5N1+8t97i32YBgUZNOG6vpaaBo/WZNBhzW8X/sFXnesz8VjJcnxQpa9vmdm32bA/+n+lK1+5oJXb+/2V9qDWwXVzkg19tTP+MLtqTmxIgY1WWmdW9TkG7UY3RUrhzjZNo4XZ0I7aO0ipaDY6Muo2T3kAuHAfr9tIAzV62Rcgpi+BodAvG31Dxrw7umM5EgFlAJE9tnD5Mdk0+jYhRf6g6R4jw8LUMtD4rl3PC5Jsb67jB8jBEuNlIN9nNmqHRbpYCxgL68XD5B/UZ9LgAVW4w3gXVneJhG2Q224iKoctouHKEzEWvRV7vKIso73vXO+gTTTnHrT9xhRTiqcGx7Uy6dr/qD9L9mgHQRsIFGYzXwGwlbG8U/KhL05oCQHsDID8h15fpSXieO4Oz/N4nv3cJbK8mty8uXU/fLTuwiAI2Zprk0fssv8G/AKn7ZGH2dOY0gAAAABJRU5ErkJggg==">
                        <img v-if="isShowIoc"
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD4AAAA+CAMAAABEH1h2AAAAq1BMVEUAAAAa8v8Z8v8a8v8a8v8a8v8a7/8a8/8a8v8a8/8a8v8a8v8a8v8b8P8b9P8a8v8b8v8a8v8a8v8a8v8c7/8d//8Z8v8a8f8a8v8a8v8a8f8a8/8a8v8a8v8a8v8a8v8a8v8Y7P8a8v8a8v8a8/8a8v8W9P8a8v8a8v8a8v8a8v8Z8/8a8/8Z8/8c9v8Y8/8l//8a8v8a8/8a8v8a8v8a8v8Z8v8a8f8a8v9pH3kOAAAAOHRSTlMA8Izzts4euuNmnE0TIi3Ec9/6wQ8El0lE52/27NGfdzEK3MmQYRaniLOtfWk8GioG1lO8hSZaNcucUuQAAALtSURBVEjH7ZaJjpswEEDXAQIJISQGzH2EOxy5j/n/L6uXUi0l7Ja0VVtV+6QEa8zTIIPH8/K3Sa8nP588Re6frmmrLw3fK9inKDzfWLa6FUeCg57CEaLYavWMwwA2M15mbADMZa0umwCOq/CjUVwHwJRbnUOAZLKajWZFZKpwre4yYGZJMB1NkGQmMO637AwIc119GY2qzwVguI5OjM14fWOQnj4XF+P1hTj/1P+wrv2KrqWStDiM0LWFtF9eU60bW1yNxLJESfuhfpB0K5+UJ6njT3U/k13XMz7WNVUKRIucj2HkzZabr7Hd3qhIhACAu32oa5K+IrwrIAy2cPaXzcPsb3nBrW2gKOL7Ok2sW0QxocVWrGA3XYp+dsRAcY58Gby7dIvaIiy3ZhrVNKmBzpdbkrOu2djrojwt1UFdVw87/XL+mhiFUUG8CAG9KStCoGDT3cZJ0KzFoK7WORVeEdg8qQOjVBgA23Qw0IvrrfRgSu1h3VBVPaM2WnPbuKpTWhTqUkbwCrOOisvp2rzFd7Nv7pMQm3xcGcFUbWrSvckPjkxj+5TaH+iLl90pzohlTN+KUr3KFPns+cZ0xJY57II6kNRuTbzWoqjXkvrzO07T/pn9/qn/Z/ovHtDkufag0TvNSTG7S6O5z4puc4IARV5pWatRWFbpRZ3WSBFea2ikKHIPpaUfjY4OgKC0uifbALbNDIAoj1H79X7Za/VqwiEMT4ERN6lafa/nvBuu+4ShgICChDDsT7l8ru9b/ZAa1mWg6Z7MZRODI3t02ONiGenh7UXS43gAkYQYh0R8nNlLIz6Tnc9hzPm7wcnfqGuL6QBGfMT4GBsPE2mzQbrdRFLNeiSzcitgLGxLOuxQVckpUDupN/uEsGe+B8vLgg1gCzIddjjzbOYHmzc9Pc2PiEEPMBgo+HHGZG/pm767eSEawnGa32Oc7+rqvfJYnn1gu93yPP1j+xSXevPdyvmTeDJATMhQ/JLQw/4f4AsyV5EinEibCgAAAABJRU5ErkJggg==">
                    </div> {{$t('computerLanguage.data')}} <i class="el-icon icons"><svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                            <path fill="currentColor"
                                d="M831.872 340.864 512 652.672 192.128 340.864a30.592 30.592 0 0 0-42.752 0 29.12 29.12 0 0 0 0 41.6L489.664 714.24a32 32 0 0 0 44.672 0l340.288-331.712a29.12 29.12 0 0 0 0-41.728 30.592 30.592 0 0 0-42.752 0z">
                            </path>
                        </svg></i>
                    <transition name="fade">
                        <div class="botBox" v-if="isBoxVisible" ref="botBox">
                            <!-- 梯队数据 -->
                            <div class="botBtnItem on" @click="showEchelonPopup">{{$t('computerLanguage.teamData')}}</div>
                            <!-- 赛鸽排名 -->
                            <div class="botBtnItem on" @click="showPigeonRankingPopup">{{$t('computerLanguage.pigeonRanking')}}</div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
// 所有数据 
// import MyDataSource from '@/assets/data/datatest.json';

// 简单数据
// import MyDataSource from '@/assets/data/test.json';
// 引入ccs
import '@/assets/css/myload.css';

// 引入轨迹数据 全部鸽子
import TrajectoryData from '@/assets/data/datatest.json';


export default {
    props: ['onlietestData', 'colseload', 'sendisHide', 'sendShowPigeons', 'sendDeviceImeiArray'],
    watch: {
        // TODO 监听 数据 变化 加载实时数据
        // 
        // onlietestData(newVal, oldVal) {


        // if (newVal && newVal.length > 0) {
        //     console.log("数据:",newVal);
        //     // this.list.push(newVal.positions);
        //     this.modelAnimate(newVal,'./mapGLB/gezia.glb');

        //   } else {
        //     // 处理空数据或未定义的情况
        //     console.log("test轨迹数据为空或未定义");
        //     // this.drawPolyline(newVal);
        //     console.log("数据:",newVal.length);
        //   }

        // }

        // TODO 监听 数据 变化
        sendDeviceImeiArray(newVal, oldVal) {
            // console.log(newVal.length,"监听 数据 变化:",newVal);
            this.hideShowDevice(newVal);

        },
        // 隐藏实体 移除实体
        sendisHide(newVal, oldVal) {
            this.removeAllEntity();

        },

        // 显示实体
        sendShowPigeons(newVal, oldVal) {
            this.showAllEntity();
        }

    },
    data() {
        return {
            isBoxVisible: false, // 样式是否显示弹框
            isHovered: false,   // 样式是否显示弹框
            isEchelonVisible: false, // 样式是否显示弹框
            isShowIoc: false, // 样式是否显示弹框

            // 是否显示排名数据
            isShowRanking: false,
            isShowRankingHovered: false,

            EchelonTime: "", // 当前时间
            echelonData_0_20: 0, // 20公里以下  
            echelonData_20_35: 0, // 
            echelonData_35_50: 0, // 35公里以上
            echelonData_50_65: 0, // 50公里以上
            echelonData_65_80: 0, // 65公里以上
            echelonData_80: 0, // 80公里以上

            circleRadius_30: 20000.0, // 米作为单位，这里 红
            circleRadius_100: 35000.0, // 使用米作为单位，这里 蓝色 
            circleRadius_200: 50000.0, // 使用米作为单位，这里 绿色
            circleRadius_300: 65000.0, // 半径为使用米作为单位，这里 紫色
            circleRadius_400: 80000.0, // 半径，这里 黄

            circleCenter_30: 0,
            circleCenter_100: 0,
            circleCenter_200: 0,
            circleCenter_300: 0,
            circleCenter_400: 0,
            tableData: TrajectoryData,
            tableData2: [{
                index: 1,
                gpsTime: "2023-11-08 09:33:20",
                accumulateOdometer: 82787,
                accumulateDuration: 4049,
                altitude: '25', // 海拔高度
                odometer: '4500',
                duration: '300',
                speed: '0',
                deviceImei: "867585332219049",
                deviceName: "鸽子",
            },
            {
                index: 2,
                gpsTime: "2023-11-08 09:33:20",
                accumulateOdometer: 82787,
                accumulateDuration: 4501,
                altitude: '25', // 海拔高度
                odometer: '4500',
                duration: '300',
                speed: '0',
                deviceImei: "867585332219050",
                deviceName: "867585332",
            },
            {
                index: 3,
                gpsTime: "2023-11-08 09:33:20",
                accumulateOdometer: 82787,
                accumulateDuration: 4503,
                altitude: '25', // 海拔高度
                odometer: '4500',
                duration: '300',
                speed: '0',
                deviceImei: "867585332219239",
                deviceName: "大鸽大1",
            },
            {
                index: 4,
                gpsTime: "2023-11-08 09:33:20",
                accumulateOdometer: 82787,
                accumulateDuration: 4504,
                altitude: '25', // 海拔高度
                odometer: '4500',
                duration: '300',
                speed: '0',
                deviceImei: "867585332219049",
                deviceName: "867585332219049",
            },
            {
                index: 5,
                gpsTime: "2023-11-08 09:33:20",
                accumulateOdometer: 82787,
                accumulateDuration: 4800,
                altitude: '25', // 海拔高度
                odometer: '4500',
                duration: '300',
                speed: '0',
                deviceImei: "867585332219049",
                deviceName: "867585332219049",
            },
            {
                index: 6,
                gpsTime: "2023-11-08 09:33:20",
                accumulateOdometer: 82787,
                accumulateDuration: 450,
                altitude: '25', // 海拔高度
                odometer: '600',
                duration: '6',
                speed: '0',
                deviceImei: "867585332219049",
                deviceName: "867585332219049",
            },

            ],


            statisticsInterval: null, // 用于存储定时器的引用


            eventStartTime: 0, //   开始时间
            eventEndTime: 0,  // 结束时间
            eventName: '',  // 赛事名称
            currentIndex: -1, // 切换实体当前索引

            startLat: 0,  // 开始纬度
            startLng: 0,  // 开始经度
            endLat: 0,  // 结束纬度
            endLng: 0,  // 结束经度

            startTime: 0,  // 开始时间
            stopTime: 0,  // 结束时间
            viewer: null,
            mLatLng: [],
            //    id:this.list[index][0].deviceImei+"--->"+this.list[index][0].deviceName,
            // TrajectoryData   MyDataSource
            list: TrajectoryData,
            processedTableData: [], // 用于存储处理后排名的数据
            list2:
                [
                    [{
                        lng: 116.405419,
                        lat: 39.918034,
                        height: 0,
                        deviceImei: "1234567890",
                        deviceName: "测试1",
                        // sjtime: "2022-02-22T08:25:57.000+0800",
                        time: 1699372800000,
                    },
                    {
                        lng: 116.2821,
                        lat: 39.918145,
                        height: 2000,
                        // sjtime: "2022-02-22T08:26:27.000+0800",
                        time: 1699375800000,
                    },
                    {
                        lng: 115.497402,
                        lat: 39.344641,
                        height: 60,
                        // sjtime: "2022-02-22T08:26:57.000+0800",
                        time: 1699376900000,
                    },],

                    // 2 
                    [{
                        lng: 116.405419,
                        lat: 39.918034,
                        height: 0,
                        // sjtime: "2022-02-22T08:25:57.000+0800",
                        time: 1699372800000,
                        deviceImei: "8888888",
                        deviceName: "测试2",
                    },
                    {
                        lng: 117.3821,
                        lat: 38.918145,
                        height: 2000,
                        // sjtime: "2022-02-22T08:26:27.000+0800",
                        time: 1699378800000,
                    },
                    {
                        lng: 118.507402,
                        lat: 38.344641,
                        height: 60,
                        // sjtime: "2022-02-22T08:26:57.000+0800",
                        time: 1699374800000,
                    },],
                    // 3

                    [{
                        lng: 116.405419,
                        lat: 39.918034,
                        height: 0,
                        // sjtime: "2022-02-22T08:25:57.000+0800",
                        time: 1699372800000,
                        deviceImei: "999999",
                        deviceName: "鸽子3",
                    },
                    {
                        lng: 117.2821,
                        lat: 38.918145,
                        height: 2000,
                        // sjtime: "2022-02-22T08:26:27.000+0800",
                        time: 1699376800000,
                    },
                    {
                        lng: 118.497402,
                        lat: 38.344641,
                        height: 60,
                        // sjtime: "2022-02-22T08:26:57.000+0800",
                        time: 1699377800000,
                    },],



                ],
        };
    },
    // 要关闭的时候
    beforeDestroy() {
        // 销毁地图
        if (window.viewer) {
            // 移除监听器
            window.viewer.clock.onTick.removeEventListener(this.mOnTick);

            window.viewer.entities.removeAll(); // 移除所有实体
            window.viewer = null;

        }

        // 在组件销毁之前清理定时器
        if (this.statisticsInterval) {
            clearInterval(this.statisticsInterval);
            this.statisticsInterval = null;
        }

        // // 移除计时器

        //  if(this.mOnTick){
        //     console.log("移除计时器 ");
        //  }


    },

    // destroyed() {
    //     if (window.viewer) {
    //         window.viewer.entities.removeAll(); // 移除所有实体
    //         window.viewer = null;
    //     }
    // },
    created() {
        this.$i18n.locale = window.sessionStorage.getItem('lang')
        // this.userId = window.sessionStorage.getItem('userId')
        // this.userName = window.sessionStorage.getItem('userName')
        // this.pwdType = window.sessionStorage.getItem('pwdType')

        //  TODO 从缓存获取赛事名称 开始 和结束
        this.eventName = window.sessionStorage.getItem('historicalTrackDeviceImei') || ''
        this.eventStartTime = window.sessionStorage.getItem('historicalTrackStartTime') || ''
        this.eventEndTime = window.sessionStorage.getItem('historicalTrackEndTime') || ''

        this.startLat = window.sessionStorage.getItem('historicalTrackStarLat') || '0'
        this.startLng = window.sessionStorage.getItem('historicalTrackStarLon') || '0'
        this.endLng = window.sessionStorage.getItem('historicalTrackEndLon') || '0'
        this.endLat = window.sessionStorage.getItem('historicalTrackEndLat') || '0'

        this.startTime = Cesium.JulianDate.fromDate(new Date(this.eventStartTime)); // 中国时间 开始时间
        this.stopTime = Cesium.JulianDate.fromDate(new Date(this.eventEndTime));  // 中国时间 结束时间 1730981637000
        // console.log("开始时间：",new Date(this.eventStartTime),"结束时间：",new Date(this.eventEndTime))


        // console.log('地图了----赛事信息:', this.eventName,this.eventStartTime,this.eventEndTime)

        // try {
        //   const response = await fetch('/data/datatest.json');
        //   const data = await response.json();
        //   this.list = data;
        // } catch (error) {
        //   console.error('Error loading data:', error);
        // }
    },

    mounted() {
        // console.log("轨迹数据2:",this.mLatLng2.flat());
        // console.log("轨迹数据:",this.mLatLng.flat());
        // cesium 地图初始化
        this.initCesiumMap();
        // 添加模型
        // this.addModel( '/mapGLB/Cesium_Air.glb',5000);
        // this.addModel('/mapGLB/pigeon1.glb', 5000);
        // console.log("闯过的指轨迹数据coordinates:",this.coordinates);
        // console.log("闯过的指轨迹数据test:",this.test);

        // TODO 创建计时器
        // 确保 Cesium Viewer 已经初始化
        if (window.viewer) {
            // 设置一个每分钟触发一次的定时器
            this.statisticsInterval = setInterval(() => {
                var currentTime = window.viewer.clock.currentTime;

                // console.log("设置一个每分钟触发一次的定时器当前时间：", currentTime);
                // 获取 entities 集合（注意：values 是一个迭代器，你可能需要将其转换为数组或使用其他方法遍历）
                // TODO 获取所有显示的 entities
                // const entities = window.viewer.entities.values;
                // 获取所有以'point_'开头的实体 和显示的实体
                var pointEntities = window.viewer.entities.values.filter(function (entity) {
                    return entity.id.startsWith('point_') && entity.show;
                });

                // 如果没有找到任何实体，则退出函数
                if (pointEntities.length === 0) {
                    // console.log("没有找到以'point_'开头的实体");
                    return;
                }

                // TODO 鸽子排名
                // 计算鸽子 和 终点的距离

                let endPosition = Cesium.Cartesian3.fromDegrees(this.endLng, this.endLat);
                // 遍历 pointEntities 并计算距离
                // pointEntities.forEach(entity => {
                //     var position = entity.position.getValue(currentTime);
                //     if (position) {
                //         // 计算当前位置与终点的距离
                //         let distance = Cesium.Cartesian3.distance(position, endPosition);

                //         // 根据 deviceImei 找到 tableData 中的对应对象并更新 endDistance
                //         let matchingData = this.processedTableData.find(data => data.deviceImei === entity.id.replace('point_', '')); // 假设 id 是 'point_' + deviceImei
                //         if (matchingData) {
                //             matchingData.endDistance = distance.toFixed(2); // 保留两位小数
                //         }
                //     }
                // });


                // 在这里添加你的统计逻辑
                // // 例如，遍历 entities 并收集数据
                // for (const entity of entities) {
                // // 处理每个 entity 的数据...
                // }
                // 优化后统计鸽子
                //  circleRadius_30 :  20000.0, // 米作为单位，这里 红
                //  circleRadius_100 : 35000.0, // 使用米作为单位，这里 蓝色 
                //  circleRadius_200 : 50000.0, // 使用米作为单位，这里 绿色
                //  circleRadius_300 : 65000.0, // 半径为使用米作为单位，这里 紫色
                //  circleRadius_400 : 80000.0, // 半径，这里 黄

                // 初始化圈内实体数量统计对象
                const circleCounts = {
                    '400': 0,
                    '300': 0,
                    '200': 0,
                    '100': 0,
                    '30': 0,
                    'outside': 0 // 用于统计不在任何圈内的实体数量
                };



                // 重置圈内实体数量统计
                Object.values(circleCounts).forEach(count => count = 0);
                // 重置排名数据
                this.processTableData();
                const _this = this;
                pointEntities.forEach(function (entity) {

                    var position = entity.position.getValue(currentTime);
                    if (position) {

                        // 计算当前位置与终点的距离
                        let distance = (Cesium.Cartesian3.distance(position, endPosition) / 1000);
                        // // 根据 deviceImei 找到 tableData 中的对应对象并更新 endDistance
                        // let matchingData = _this.processedTableData.find(data => data.deviceImei === entity.id.replace('point_', '')); // 假设 id 是 'point_' + deviceImei
                        // if (matchingData) {
                        //     matchingData.endDistance = distance.toFixed(2); // 保留两位小数
                        // }

                        // 将IMEI从entity.id中提取出来
                        let imei = entity.id.replace('point_', '');

                        // 查找匹配的数据对象（假设processedTableData是可变的）
                        let matchingDataIndex = _this.processedTableData.findIndex(data => data.deviceImei === imei);
                        if (matchingDataIndex !== -1) {
                            // 更新匹配对象的endDistance（保留两位小数并转换回数字）
                            _this.processedTableData[matchingDataIndex].endDistance = distance.toFixed(2);

                            // 如果processedTableData是Vue组件的data属性，并且您希望Vue能够响应这个变化，
                            // 并且您没有使用Vue.set（因为我们在直接索引上修改），则可能需要通知Vue这个变化，
                            // 但通常直接修改数组元素应该足以触发更新（取决于Vue版本和配置）。
                            // 如果不更新，可以考虑使用Vue.set或替换整个数组。
                        } else {
                            // 可选：如果找不到匹配项，可以记录错误或执行其他逻辑
                            console.error(`No matching data found for IMEI: ${imei}`);
                        }



                        let insideCircle = false;

                        if (Cesium.Cartesian3.distance(position, _this.circleCenter_400) <= _this.circleRadius_400) {
                            if (Cesium.Cartesian3.distance(position, _this.circleCenter_300) > _this.circleRadius_300) {
                                // console.log("计算鸽子 和 终点的距离endPosition:", Cesium.Cartesian3.distance(position, endPosition));
                                // 检查每个圈
                                circleCounts['400']++;
                                insideCircle = true;
                            } else {
                                // 如果在300圈内，则继续检查更小的圈
                                if (Cesium.Cartesian3.distance(position, _this.circleCenter_300) <= _this.circleRadius_300) {
                                    if (Cesium.Cartesian3.distance(position, _this.circleCenter_200) > _this.circleRadius_200) {

                                        circleCounts['300']++;
                                        insideCircle = true;
                                    } else {
                                        // 如果在200圈内，则继续检查更小的圈
                                        if (Cesium.Cartesian3.distance(position, _this.circleCenter_200) <= _this.circleRadius_200) {
                                            if (Cesium.Cartesian3.distance(position, _this.circleCenter_100) > _this.circleRadius_100) {


                                                circleCounts['200']++;
                                                insideCircle = true;
                                            } else {
                                                // 如果在100圈内，则继续检查更小的圈
                                                if (Cesium.Cartesian3.distance(position, _this.circleCenter_100) <= _this.circleRadius_100) {
                                                    if (Cesium.Cartesian3.distance(position, _this.circleCenter_30) > _this.circleRadius_30) {

                                                        circleCounts['100']++;
                                                        insideCircle = true;
                                                    } else {
                                                        // 在30圈内

                                                        circleCounts['30']++;
                                                        insideCircle = true;
                                                    }
                                                } else {
                                                    // 在100圈外但在其他圈内的情况已在上面的else中处理
                                                }
                                            }
                                        } else {
                                            // 在200圈外但在其他圈内的情况已在上面的else中处理
                                        }
                                    }
                                } else {
                                    // 在300圈外但在其他圈内的情况已在上面的else中处理
                                }
                            }
                        } else {
                            // 如果不在400圈内，则标记为outside并设置默认颜色

                            circleCounts['outside']++;
                        }

                        // 如果未在任何特定圈内标记，则也应视为outside（理论上这不会发生，因为上面已经处理了所有情况）
                        if (!insideCircle) {
                            circleCounts['outside']++;
                        }
                    }

                });

                // 输出每个圈内的数量到控制台（或用于其他目的）
                // console.log("圈内的数量",circleCounts);
                // {30: 1, 100: 0, 200: 49, 300: 22, 400: 0, outside: 18}
                //TODO 把数据传到页面页面展示
                // echelonData_0_20: 0, // 20公里以下  
                // echelonData_20_35: 0, // 
                // echelonData_35_50: 0, // 35公里以上
                // echelonData_50_65: 0, // 50公里以上
                // echelonData_65_80: 0, // 65公里以上
                // echelonData_80: 0, // 80公里以上
                // 计算 circleCounts 总数
                const totalCount = Object.values(circleCounts).reduce((sum, count) => sum + count);
                // console.log("圈内的总数",totalCount);
                // 统计 在 30 中的比例 按百分比 保留两位小数
                this.echelonData_0_20 = (circleCounts['30'] / totalCount * 100).toFixed(2);
                // 统计 在 100 中的比例 按百分比 保留两位小数
                this.echelonData_20_35 = (circleCounts['100'] / totalCount * 100).toFixed(2);
                // 统计 在 200 中的比例 按百分比 保留两位小数
                this.echelonData_35_50 = (circleCounts['200'] / totalCount * 100).toFixed(2);
                // 统计 在 300 中的比例 按百分比 保留两位小数
                this.echelonData_50_65 = (circleCounts['300'] / totalCount * 100).toFixed(2);
                // 统计 在 400 中的比例 按百分比 保留两位小数
                this.echelonData_65_80 = (circleCounts['400'] / totalCount * 100).toFixed(2);
                // 统计 在 outside 中的比例 按百分比 保留两位小数
                this.echelonData_80 = (circleCounts['outside'] / totalCount * 100).toFixed(2);




                // 注意：如果 entities 集合很大，这里的处理可能会很慢。
                // 考虑使用 Web Workers 或其他优化策略来避免阻塞 UI 线程。
            }, 500); // TODO 60000 毫秒 = 1 分钟 = 1 分钟 半秒
        } else {
            console.error('Cesium Viewer is not initialized!');
        }

        // TODO 获取假的数据
        this.processTableData();

    },
    computed: {
        // TODO 计算排名
        sortedTableData() {
            return this.processedTableData.slice().sort((a, b) => a.endDistance - b.endDistance);
        },
    },
    methods: {
        // TODO 样式
        showBox() {
            this.isBoxVisible = true;
            this.isShowIoc = true;
            // 可选：调整botBox的位置以正好显示在“数据”上方
            this.$nextTick(() => {
                const botBox = this.$refs.botBox;
                // 这里可以根据需要添加逻辑来调整botBox的样式，比如top和left属性
                // 注意：由于botBox使用了绝对定位，你可能需要为其父元素设置相对定位（position: relative;）
            });
        },
        hideBox() {
            this.isBoxVisible = false;
            this.isShowIoc = false;
        },
        showEchelonPopup() {
            // 判断赛鸽排名窗口是否展示 展示就要关闭
            if (this.isShowRanking) {
                this.isShowRanking = false;
            }
            // 显示阶梯数据
            this.isEchelonVisible = true;
            // console.log("echelonPopup")
        },
        // 显示赛鸽排名
        showPigeonRankingPopup() {
            // 判断阶梯数据窗口是否展示 展示就要关闭
            if (this.isEchelonVisible) {
                this.isEchelonVisible = false;
            }
            // 显示赛鸽排名(
            this.isShowRanking = true;
        },
        // 关闭阶梯数据
        closeEchelon() {
            this.isEchelonVisible = false;
        },
        // 关闭赛鸽排名
        closeRanking() {
            this.isShowRanking = false;
        },
        //  结束样式
        // TODO 获取假数据方法
        processTableData() {
            let idCounter = 1; // 初始化ID计数器

            // console.log("distance", this.startLng, this.startLat)

            let startPosition = Cesium.Cartesian3.fromDegrees(this.startLng, this.startLat);
            let endPosition = Cesium.Cartesian3.fromDegrees(this.endLng, this.endLat);

            // // TODO 计算 起点和终点的距离
            // // 计算距离 保留2位小数
            let distance = (Cesium.Cartesian3.distance(startPosition, endPosition) / 1000).toFixed(2);
            // console.log("distance",distance)
            // let endDistance = 10;
            this.processedTableData = this.tableData.map(array => {
                if (array.length > 0) {
                    return {
                        index: idCounter++,
                        deviceImei: array[0].deviceImei,
                        deviceName: array[0].deviceName,
                        endDistance: distance
                    };
                }
                return null; // 如果数组为空，返回 null 或处理为空对象等
            }).filter(item => item !== null); // 过滤掉 null 值
        },
        // TODO 11监听时钟变化
        mOnTick(clock) {
            const _this = this;
            var currentTime = clock.currentTime;
            var entities = window.viewer.entities.values;
            this.EchelonTime = this.CesiumDateTimeXFormatter(currentTime);
            //    console.log("this.EchelonTime",this.EchelonTime)
            entities.forEach(function (entity) {
                // 只处理以'point_'开头的实体，假设这些是您想要监控的点
                if (entity.id.startsWith('point_')) {

                    var position = entity.position.getValue(currentTime);
                    // 校验  position
                    if (position) {

                        if (Cesium.Cartesian3.distance(position, _this.circleCenter_400) <= _this.circleRadius_400
                            && Cesium.Cartesian3.distance(position, _this.circleCenter_300) > _this.circleRadius_300) {
                            // 实时监控每只鸽子

                            entity.point.color = Cesium.Color.YELLOW;
                        } else if (Cesium.Cartesian3.distance(position, _this.circleCenter_300) <= _this.circleRadius_300
                            && Cesium.Cartesian3.distance(position, _this.circleCenter_200) > _this.circleRadius_200) {

                            entity.point.color = Cesium.Color.PURPLE;
                        } else if (Cesium.Cartesian3.distance(position, _this.circleCenter_200) <= _this.circleRadius_200
                            && Cesium.Cartesian3.distance(position, _this.circleCenter_100) > _this.circleRadius_100) {

                            entity.point.color = Cesium.Color.GREEN;
                        } else if (Cesium.Cartesian3.distance(position, _this.circleCenter_100) <= _this.circleRadius_100
                            && Cesium.Cartesian3.distance(position, _this.circleCenter_30) > _this.circleRadius_30) {

                            entity.point.color = Cesium.Color.BLUE;

                        } else if (Cesium.Cartesian3.distance(position, _this.circleCenter_30) <= _this.circleRadius_30) {

                            entity.point.color = Cesium.Color.RED;

                        } else {
                            entity.point.color = Cesium.Color.WHITE; // 或者其他默认颜色

                        }

                    }

                }
            });





        },

        // 绘制轨迹线
        drawPolyline(coordinates) {
            // 遍历数据 循环添加轨迹线
            for (let i = 0; i < coordinates.length; i++) {
                const positions = []; // 创建一个空数组来存储Cartesian3对象
                for (let j = 0; j < coordinates[i].length; j++) {
                    // 将每个经纬度对转换为Cartesian3对象，并添加到positions数组中
                    // const [lon, lat] = coordinates[i][j];
                    positions.push(Cesium.Cartesian3.fromDegrees(coordinates[i][j].lng, coordinates[i][j].lat));
                }
                // 使用positions数组创建polyline实体，并添加到viewer中
                window.viewer.entities.add({
                    polyline: {
                        positions: positions, // 使用转换后的Cartesian3对象数组
                        width: 5,
                        material: Cesium.Color.fromCssColorString(this.getRandomColor()),
                    }
                });
            }

            //           // 绘制线
            // var dataSource = new Cesium.CustomDataSource("linedata");
            //             window.viewer.dataSources.add(dataSource);
            //             dataSource.entities.add({
            //                 polyline: {
            //                     name: "line",

            //                     positions: positions,
            //                     width: 5,
            //                     material: Cesium.Color.fromCssColorString("#00ff00"),

            //                 }
            //             });

            //     window.viewer.zoomTo(dataSource)
        },
        // TODO 初始化地图
        initCesiumMap() {
            (Cesium.Ion.defaultAccessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJmY2Q4ZjNhYy0yM2FhLTRlNmItOGE2OC00ZDA1MTVlNzYyNmMiLCJpZCI6MjUxMjMxLCJpYXQiOjE3MzA0NTIyMjh9.fWsd13QqoCAG-EtYrZFXt_4Wqsy4tmn-yJ4MEuMvIIg");
            // 备份自己的  eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJmY2Q4ZjNhYy0yM2FhLTRlNmItOGE2OC00ZDA1MTVlNzYyNmMiLCJpZCI6MjUxMjMxLCJpYXQiOjE3MzA0NTIyMjh9.fWsd13QqoCAG-EtYrZFXt_4Wqsy4tmn-yJ4MEuMvIIg
            // 詹总  eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI4YWFiNmJiMS1jNjRjLTRkM2QtYTgzOC05MTMwZDJjOGJmNzIiLCJpZCI6MjU2MDE3LCJpYXQiOjE3MzIwMTgzMDl9.N5fr5-Z7OGnVoirhJ-wA3NzQ4TFDidiw9cUzlQBZGKc
            //      eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJkYzUwYjE0NS04ZTQ0LTRkMWMtYTI4Yi1iOWFhNDMzNDY3ZTAiLCJpZCI6MjUxMjMxLCJpYXQiOjE3MzE4OTg0OTR9.mj5LfUew2Hw2PyB9Fctyv4_KzPXv4GnyvHDlTqaNODQ
     
              // 从缓存中获取语言 如果 当期系统语言是英文 则不添加高德地图标注
              const lang = window.sessionStorage.getItem('lang');

              if (lang !== 'en-US') { //  中文

                window.viewer = new Cesium.Viewer("cesiumContainer", {
                    // 优化

                    // requestRenderMode : true, // 开启渲染模式
                    // maximumRenderTimeChange : Infinity, // 最大渲染时间


                    homeButton: true,
                    sceneModePicker: true,  // 地图模式
                    baseLayerPicker: false, // 影像切换
                    animation: true, // 是否显示动画控件
                    infoBox: false, // 是否显示点击要素之后显示的信息
                    selectionIndicator: false, // 要素选中框
                    geocoder: false, // 是否显示地名查找控件
                    timeline: true, // 是否显示时间线控件
                    fullscreenButton: true, // 是否显示全屏控件
                    shouldAnimate: false,   // 默认不播放动画
                    navigationHelpButton: false, // 是否显示帮助信息控件
                    creditContainer: document.createElement("div"),
                    infoBox: true, // 是否显示点击要素之后显示的信息
                    // 最小缩放




                    // 地形 ok
                    // terrain: Cesium.Terrain.fromWorldTerrain(), // 地形数据
                    //   地形 ok
                    terrain: Cesium.Terrain.fromWorldTerrain({
                        requestWaterMask: false, // 水面蒙版
                        requestVertexNormals: true  //
                    }),

                    // 影像 imageryProvider
                    // imageryProvider: new Cesium.SingleTileImageryProvider({
                    //     url: require("../../assets/images/map/1.jpg"),
                    // }),



                });
            }else{

                window.viewer = new Cesium.Viewer("cesiumContainer", {
                    // 优化

                    // requestRenderMode : true, // 开启渲染模式
                    // maximumRenderTimeChange : Infinity, // 最大渲染时间


                    homeButton: true,
                    sceneModePicker: true,  // 地图模式
                    baseLayerPicker: false, // 影像切换
                    animation: true, // 是否显示动画控件
                    infoBox: false, // 是否显示点击要素之后显示的信息
                    selectionIndicator: false, // 要素选中框
                    geocoder: false, // 是否显示地名查找控件
                    timeline: true, // 是否显示时间线控件
                    fullscreenButton: true, // 是否显示全屏控件
                    shouldAnimate: false,   // 默认不播放动画
                    navigationHelpButton: false, // 是否显示帮助信息控件
                    creditContainer: document.createElement("div"),
                    infoBox: true, // 是否显示点击要素之后显示的信息
                    // 最小缩放




                    // 地形 ok
                    // terrain: Cesium.Terrain.fromWorldTerrain(), // 地形数据
                    //   地形 ok
                    terrain: Cesium.Terrain.fromWorldTerrain({
                        requestWaterMask: false, // 水面蒙版
                        requestVertexNormals: true  //
                    }),

                     // 英文标注
                     baseLayer: Cesium.ImageryLayer.fromWorldImagery({
                        style: Cesium.IonWorldImageryStyle.AERIAL_WITH_LABELS,
                    }),



                });
            }



            // 显示地图阴影
            // window.viewer.shadows= true;
            // window.viewer.scene.globe.enableLighting = true;
            // 显示帧率组件
            window.viewer.scene.debugShowFramesPerSecond = true;
            //  TODO 优化性能
            // window.viewer.scene.requestRenderMode = true;   // 开启渲染模式 加速
            // window.viewer.scene.debugShowFramesPerSecond = true;
            // window.viewer.resolutionScale = 0.9; // 默认值为 1.0；

            // window.viewer.scene.msaaSamples = 4 // 抗锯齿
            // window.viewer.scene.postProcessStages.fxaa.enabled = true; // 开启抗锯齿
            // window.viewer.highDynamicRangeSupported && (window.viewer.scene.highDynamicRange = true); // 开启高动态范围渲染
            // // viewer.resolutionScale = window.devicePixelRatio; // 分辨率

            // Hides the stars
            window.viewer.scene.skyBox.show = false;
            // Explicitly render a new frame
            window.viewer.scene.requestRender();

            window.viewer.scene.postProcessStages.fxaa.enabled = true; // 开启抗锯齿

            // 2 js 性能优化 降低屏幕空间误差，提高渲染效率
            window.viewer.scene.globe.maximumScreenSpaceError = 4/3;
            window.viewer.highDynamicRangeSupported && (window.viewer.scene.highDynamicRange = true); // 开启高动态范围渲染
            window.viewer.resolutionScale = window.devicePixelRatio; // 分辨率


            // 绘制起点终点
            // TODO 绘制贴地线   lng: 116.405419,
            // lat: 39.918034,   lng: 118.497402,
            // lat: 38.344641,
            // startPosition :0,
            // endPosition :0,
            let startPosition = Cesium.Cartesian3.fromDegrees(this.startLng, this.startLat);
            let endPosition = Cesium.Cartesian3.fromDegrees(this.endLng, this.endLat);
            this.createGroundLineAndBillboards(startPosition, endPosition);




            // 计算距离
            // var distance=Cesium.Cartesian3.distance(this.startPosition,this.endPosition)

            // console.log( "计算直线距离 两点距离" ,distance)

            // console.log("测试开始时间--->:",new Date(1694448065000));


            // window.viewer.zoomTo(circleEntity);

            // this.drawPolyline(this.list);

            // TODO 通过 ID 获取实体
            // const dynamicEntity = window.viewer.entities.getById("enclosure_30");
            // console.log("绘制一个中心透明获取的实体", dynamicEntity.position);


            // TODO 调用模型动画 
            // ./mapGLB/gezia.glb

            // this.modelAnimate(this.list,'/mapGLB/pigeon1.glb');
            this.modelAnimate(this.list, './mapGLB/gezia.glb');



            // const positions = [
            // Cesium.Cartesian3.fromDegrees(101.8, 33.74, 620.01),
            // Cesium.Cartesian3.fromDegrees(-112.87351, 36.27992, 617.9),
            // Cesium.Cartesian3.fromDegrees(-112.87081, 36.2816, 617.6),
            // Cesium.Cartesian3.fromDegrees(-112.86539, 36.28239, 625.36),
            // Cesium.Cartesian3.fromDegrees(-112.86108, 36.28137, 627.82),
            // Cesium.Cartesian3.fromDegrees(-112.85551, 36.27967, 625.54),
            // Cesium.Cartesian3.fromDegrees(-112.848, 36.27732, 628.9),
            // Cesium.Cartesian3.fromDegrees(-112.84086, 36.27739, 638.81),
            // Cesium.Cartesian3.fromDegrees(-112.83682, 36.27995, 643.31),
            // ];

            // // 定义折线实体  画一条线
            // var polylineEntity = window.viewer.entities.add({
            //     polyline: {
            //         positions: positions,
            //         width: 5,
            //         material: new Cesium.PolylineGlowMaterialProperty({
            //             glowPower: 0.1,
            //             color: Cesium.Color.fromCssColorString(this.getRandomColor()),
            //         })
            //         }
            //     });

            //   //这是让你的画面以一个怎样的形式出现，相当于出场动画
            //   window.viewer.camera.flyTo({
            //     //   lon: 116.405419,
            //     // lat: 39.918034,
            //     // fromDegrees()方法，将经纬度和高程转换为世界坐标，这里定位到中国  101.8, 33.74,  121.795693, 25.146142
            //     destination: Cesium.Cartesian3.fromDegrees(116.405419, 39.918034, 500000),
            //     orientation: {
            //         // 指向
            //         // heading:Cesium.Math.toRadians(45,0),
            //         // 视角
            //         pitch:Cesium.Math.toRadians(-90),

            //         roll: 0.0,
            //     },
            // });



            // 自定义主页按钮
            // 保存当前上下文的viewer引用
            // var viewerRef = window.viewer;
            // TODO 自定义主页按钮
            // window.viewer.homeButton.viewModel.command.beforeExecute.addEventListener(function (e) {
            //     e.cancel = true;
            //     // 你要飞的位置
            //     viewerRef.camera.flyTo({ // ( this.startLng, this.startLat);
            //         destination: Cesium.Cartesian3.fromDegrees(this.startLng, this.startLat, 5000.0),
            //         duration: 1,	// 以秒为单位的飞行持续时间。
            //         offset : {
            //             heading: Cesium.Math.toRadians(0.0),	// 以弧度为单位的航向角。
            //             pitch: -Math.PI / 2,					// 以弧度为单位的俯仰角。
            //             range: 10								// 到中心的距离，以米为单位。								
            //         }
            //     });
            // });
            window.viewer.homeButton.viewModel.command.beforeExecute.addEventListener((e) => {
                e.cancel = true;
                // 你要飞的位置
                window.viewer.camera.flyTo({
                    destination: Cesium.Cartesian3.fromDegrees(this.startLng, this.startLat, 500000.0),
                    duration: 1, // 以秒为单位的飞行持续时间。
                    offset: {
                        heading: Cesium.Math.toRadians(0.0),	// 以弧度为单位的航向角。
                        pitch: -Math.PI / 2,					// 以弧度为单位的俯仰角。
                        range: 10								// 到中心的距离，以米为单位。								
                    }
                });
            });


            // 加载OSM在线地图（标准风格）
            // window.viewer.imageryLayers.addImageryProvider(
            //     new Cesium.UrlTemplateImageryProvider({
            //         url: 'https://tile-{s}.openstreetmap.fr/hot/{z}/{x}/{y}.png',
            //         subdomains: ["a", "b", "c", "d"],
            //     })
            // );

            // 添加天地图影像注记底图 注册开发者，设置域名白名单 
            // window.viewer.imageryLayers.addImageryProvider(new Cesium.WebMapTileServiceImageryProvider({
            //     url: "http://t0.tianditu.gov.cn/cia_w/wmts?tk=f180b8af3efcf798a9281dd0f0d3dcae",
            //     layer: "cia",
            //     style: "default",
            //     tileMatrixSetID: "w",
            //     format: "tiles",
            //     maximumLevel: 18
            // }))


            // 如果需要叠加高德/百度注记地图则添加以下代码
            window.viewer.imageryLayers.addImageryProvider(new Cesium.UrlTemplateImageryProvider({
                url: "http://webst02.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scale=1&style=8",
                layer: "tdtAnnoLayer",
                style: "default",
                format: "image/jpeg",
                tileMatrixSetID: "GoogleMapsCompatible"
            }))

            // 自定义的近地天空盒 近景天空
            let groundSkybox = new Cesium.SkyBox({
                sources: {
                    negativeX: require('@/assets/skyBox/lantian/Left.jpg'),
                    positiveX: require('@/assets/skyBox/lantian/Right.jpg'),
                    negativeY: require('@/assets/skyBox/lantian/Back.jpg'),
                    positiveY: require('@/assets/skyBox/lantian/Front.jpg'),
                    negativeZ: require('@/assets/skyBox/lantian/Down.jpg'),
                    positiveZ: require('@/assets/skyBox/lantian/Up.jpg')
                }
            })

            // 自带的默认天空盒
            // let defaultSkybox = window.viewer.scene.skyBox;
            // 自定义的近地天空盒 远处 天空
            let defaultSkybox = new Cesium.SkyBox({
                sources: {
                    negativeX: require('@/assets/skyBox/5/tycho2t3_80_mx.jpg'),
                    positiveX: require('@/assets/skyBox/5/tycho2t3_80_px.jpg'),
                    negativeY: require('@/assets/skyBox/5/tycho2t3_80_my.jpg'),
                    positiveY: require('@/assets/skyBox/5/tycho2t3_80_py.jpg'),
                    negativeZ: require('@/assets/skyBox/5/tycho2t3_80_mz.jpg'),
                    positiveZ: require('@/assets/skyBox/5/tycho2t3_80_pz.jpg')
                }
            })
            // 渲染前监听并判断相机位置
            window.viewer.scene.preUpdate.addEventListener(() => {
                let position = window.viewer.scene.camera.position;
                let cameraHeight = Cesium.Cartographic.fromCartesian(position).height;
                if (cameraHeight < 240000) {
                    window.viewer.scene.skyBox = groundSkybox;
                    window.viewer.scene.skyAtmosphere.show = false;
                } else {
                    window.viewer.scene.skyBox = defaultSkybox;
                    window.viewer.scene.skyAtmosphere.show = true;
                }
            })





            // 立即设置时间格式化函数
            window.viewer.animation.viewModel.dateFormatter = this.CesiumDateFormatter;
            window.viewer.animation.viewModel.timeFormatter = this.CesiumTimeFormatter;
            Cesium.Timeline.prototype.makeLabel = this.CesiumDateTimeFormatter;

            // 设置始时钟始时间
            window.viewer.clock.startTime = this.startTime.clone();

            // const julianDate = Cesium.JulianDate.fromDate(utcDate);  2023-11-08 08:32:00
            let currentTime = Cesium.JulianDate.fromDate(new Date(1699403520000)); // 中国时间 开始时间

            // 设置时钟当前时间
            window.viewer.clock.currentTime = currentTime.clone();
            // 时间速率，数字越大时间过的越快
            window.viewer.clock.multiplier = 10;
            window.viewer.clock.stopTime = this.stopTime.clone();

            // 假设start已经在之前定义过了，否则你需要从firstProperty中获取startTime
            // var start = times[0]; // 如果需要从第一个轨迹中获取开始时间的话

            // 时间轴（使用第一个轨迹的开始和停止时间）
            window.viewer.timeline.zoomTo(this.startTime, this.stopTime);

            // 循环执行，到达终止时间，重新从起点时间开始
            window.viewer.clock.clockRange = Cesium.ClockRange.LOOP_STOP;


            // var circleRadius_30 =  20000.0; // 米作为单位，这里 红
            // var circleRadius_100 = 35000.0; // 使用米作为单位，这里 蓝色 
            // var circleRadius_200 = 50000.0; // 使用米作为单位，这里 绿色
            // var circleRadius_300 = 65000.0; // 半径为使用米作为单位，这里 紫色
            // var circleRadius_400 = 80000.0; // 半径，这里 黄

            // TODO 绘制实体圆圈
            this.createCircleEntity(endPosition, this.circleRadius_30, 'enclosure_30', "#ff0000");
            this.createCircleEntity(endPosition, this.circleRadius_100, 'enclosure_100', "#0000ff");
            this.createCircleEntity(endPosition, this.circleRadius_200, 'enclosure_200', "#00ff00");
            this.createCircleEntity(endPosition, this.circleRadius_300, 'enclosure_300', "#ff00ff");
            this.createCircleEntity(endPosition, this.circleRadius_400, 'enclosure_400', "#ffff00");

            // 通过 ID 获取实体
            const dynamicEntity_30 = window.viewer.entities.getById("enclosure_30");
            const dynamicEntity_100 = window.viewer.entities.getById("enclosure_100");
            const dynamicEntity_200 = window.viewer.entities.getById("enclosure_200");
            const dynamicEntity_300 = window.viewer.entities.getById("enclosure_300");
            const dynamicEntity_400 = window.viewer.entities.getById("enclosure_400");

            // console.log("绘制一个中心透明获取的实体position", dynamicEntity.position.getValue());

            this.circleCenter_30 = dynamicEntity_30.position.getValue();
            this.circleCenter_100 = dynamicEntity_100.position.getValue();
            this.circleCenter_200 = dynamicEntity_200.position.getValue();
            this.circleCenter_300 = dynamicEntity_300.position.getValue();
            this.circleCenter_400 = dynamicEntity_400.position.getValue();

            //TODO 调用绘制广告牌
            //  this.createIntersectionBillboards(this.startLng, this.startLat,this.endLng,  this.endLat,this.endLng,  this.endLat,circleRadius_30);

            // TODO 点击事件

            // var handler = new Cesium.ScreenSpaceEventHandler(window.viewer.scene.canvas);
            //     handler.setInputAction((click) => {
            //         console.log('左键单击事件：', click.position);
            //         this.processedTableData[0].endDistance = 12;

            //         // 获取当前时间
            //         var currentTime = window.viewer.clock.currentTime;
            //         console.log("当前时间按-》",this.CesiumDateTimeXFormatter(currentTime));

            //     //   // 获取当前时间（ISO 8601 格式） 2023-11-08T00:30:00Z
            //     //     console.log("当前时间按-》",Cesium.JulianDate.toIso8601(currentTime));
            //     //     // 将 JulianDate 转换为 Date 对象（注意：这将基于 UTC 时间）
            //     //     var date = Cesium.JulianDate.toDate(currentTime);

            //     //     // 创建一个选项对象来指定日期时间格式
            //     //     var options = {
            //     //         year: 'numeric', month: '2-digit', day: '2-digit',
            //     //         hour: '2-digit', minute: '2-digit', second: '2-digit',
            //     //         hour12: false, // 使用 24 小时制
            //     //         timeZoneName: 'short' // 这将显示时区名称，但我们可以稍后移除它
            //     //     };

            //     //     // 使用 Intl.DateTimeFormat 来格式化日期时间
            //     //     var formatter = new Intl.DateTimeFormat('zh-CN', options);
            //     //     var formattedParts = formatter.formatToParts(date);

            //     //     // 手动构建你想要的字符串格式，不考虑时区名称
            //     //     var formattedDate = '';
            //     //     formattedParts.forEach(part => {
            //     //         if (part.type !== 'timeZoneName') {
            //     //             formattedDate += part.value;
            //     //             if (['hour', 'minute', 'second'].includes(part.type) && formattedDate[formattedDate.length - 1] !== ':') {
            //     //                 // 在小时、分钟和秒之间添加空格（如果它们不是以冒号结尾的话）
            //     //                 formattedDate += '';
            //     //             }
            //     //         }
            //     //     });

            //     //     console.log('当前时间按-》', formattedDate);





            //         var ray = window.viewer.camera.getPickRay(click.position);
            //         var position = window.viewer.scene.globe.pick(ray, window.viewer.scene);
            //         if (position) {
            //             console.log('位置信息position：', position);
            //             console.log('位置信息：', Cesium.Cartographic.fromCartesian(position));
            //             // var cartographicPositions = Cesium.Ellipsoid.WGS84.cartesianArrayToCartographicArray(position);
            //             // console.log('WGS848经纬度信息：x , y , z ',cartographicPositions);
            //         }
            //     }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

            // TODO 绘制公里
            window.viewer.entities.add({
                position: { x: -2967602.291477882, y: 5042542.63032606, z: 2530832.860173073 },
                // 广告牌
                label: {
                    // text: '20公里',
                    text: '20' + this.$t('computerLanguage.kilometer'),
                    // scale : 1.5,       // 大小
                    font: '24px sans-serif', // 设置字体大小
                    style: Cesium.LabelStyle.FILL_AND_OUTLINE, // 样式 
                    fillColor: Cesium.Color.WHITE,
                    // outlineColor: Cesium.Color.BLACK, // 轮廓线
                    // outlineWidth: 2,    // // 轮廓线宽度
                    // showBackground: true, // 背景
                    // backgroundColor: Cesium.Color.BLUE,
                    heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考 旋转时不会偏移
                    pixelOffset: new Cesium.Cartesian2(0, -50), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平
                    eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面    


                    // font: '20px Helvetica',
                    // font: '14px sans-serif',
                    // fillColor: Cesium.Color.RED, 
                    // outlineWidth: 2,
                    // outlineColor: Cesium.Color.BLACK
                },
            });

            //35   {x: -2968322.081354647, y: 5035404.077140281, z: 2543951.1493177502}

            window.viewer.entities.add({
                position: { x: -2968322.081354647, y: 5035404.077140281, z: 2543951.1493177502 },
                // 广告牌
                label: {
                    // text: '35公里',
                    text: '35' + this.$t('computerLanguage.kilometer'),
                    // scale : 1.5,       // 大小
                    font: '24px sans-serif', // 设置字体大小
                    style: Cesium.LabelStyle.FILL_AND_OUTLINE, // 样式 
                    fillColor: Cesium.Color.WHITE,
                    heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考 旋转时不会偏移
                    pixelOffset: new Cesium.Cartesian2(0, -50), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平
                    eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面    
                },
            });
            //50  {x: -2969523.8139385805, y: 5023475.059736933, z: 2565870.680219716}
            window.viewer.entities.add({
                position: { x: -2969051.4843325014, y: 5028271.745112436, z: 2557093.697250429 },
                // 广告牌
                label: {
                    // text: '50公里',
                    text: '50' + this.$t('computerLanguage.kilometer'),
                    // scale : 1.5,       // 大小
                    font: '24px sans-serif', // 设置字体大小
                    style: Cesium.LabelStyle.FILL_AND_OUTLINE, // 样式 
                    fillColor: Cesium.Color.WHITE,
                    heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考 旋转时不会偏移
                    pixelOffset: new Cesium.Cartesian2(0, -50), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平
                    eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面    
                },
            });

            // 65
            window.viewer.entities.add({
                position: { x: -2969759.160827037, y: 5021091.037034286, z: 2570234.437052611 },
                // 广告牌
                label: {
                    // text: '65公里',
                    text: '65' + this.$t('computerLanguage.kilometer'),
                    // scale : 1.5,       // 大小
                    font: '24px sans-serif', // 设置字体大小
                    style: Cesium.LabelStyle.FILL_AND_OUTLINE, // 样式 
                    fillColor: Cesium.Color.WHITE,
                    heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考 旋转时不会偏移
                    pixelOffset: new Cesium.Cartesian2(0, -50), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平
                    eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面    
                },
            });


            // 80
            window.viewer.entities.add({
                position: { x: -2970453.47114406, y: 5013898.453903222, z: 2583347.383230127 },
                // 广告牌
                label: {
                    // text: '80公里',
                    text: '80' + this.$t('computerLanguage.kilometer'),
                    // scale : 1.5,       // 大小
                    font: '24px sans-serif', // 设置字体大小
                    style: Cesium.LabelStyle.FILL_AND_OUTLINE, // 样式 
                    fillColor: Cesium.Color.WHITE,
                    heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考 旋转时不会偏移
                    pixelOffset: new Cesium.Cartesian2(0, -50), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平
                    eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面    
                },
            });

            // 在 400 里面 大于300 的 小于 400的  是黄色 
            // 300 里面 大于200 的 小于 300的  是紫色
            // 200 里面 大于100 的 小于 200的  是绿色
            // 100 里面 大于30 的 小于 100的  是蓝色
            // 小于30 是红色
            // 其它是白色
            // // TODO 这里 111啊 监听时钟变化
            // const _this = this;
            window.viewer.clock.onTick.addEventListener(this.mOnTick);

            // window.viewer.clock.onTick.addEventListener(function(clock) {
            //     var currentTime = clock.currentTime;
            //     var entities = window.viewer.entities.values;
            //     _this.EchelonTime = _this.CesiumDateTimeXFormatter(currentTime);
            //     entities.forEach(function(entity) {
            //         // 只处理以'point_'开头的实体，假设这些是您想要监控的点
            //         if (entity.id.startsWith('point_')) {

            //             var position = entity.position.getValue(currentTime);
            //             // 校验  position
            //             if(position){

            //                 if (Cesium.Cartesian3.distance(position, _this.circleCenter_400) <= _this.circleRadius_400 
            //                 && Cesium.Cartesian3.distance(position, _this.circleCenter_300) > _this.circleRadius_300) {
            //                     // 实时监控每只鸽子

            //                     entity.point.color = Cesium.Color.YELLOW;
            //                 }else if (Cesium.Cartesian3.distance(position, _this.circleCenter_300) <= _this.circleRadius_300 
            //                 && Cesium.Cartesian3.distance(position, _this.circleCenter_200) > _this.circleRadius_200) {

            //                     entity.point.color = Cesium.Color.PURPLE;
            //                 }else if (Cesium.Cartesian3.distance(position, _this.circleCenter_200) <= _this.circleRadius_200 
            //                 && Cesium.Cartesian3.distance(position, _this.circleCenter_100) > _this.circleRadius_100) {

            //                     entity.point.color = Cesium.Color.GREEN;
            //                 }else if (Cesium.Cartesian3.distance(position, _this.circleCenter_100) <= _this.circleRadius_100 
            //                 && Cesium.Cartesian3.distance(position, _this.circleCenter_30) > _this.circleRadius_30) {

            //                     entity.point.color = Cesium.Color.BLUE;

            //                 }else if (Cesium.Cartesian3.distance(position, _this.circleCenter_30) <= _this.circleRadius_30) {

            //                     entity.point.color = Cesium.Color.RED;

            //                 }else {
            //                     entity.point.color = Cesium.Color.WHITE; // 或者其他默认颜色

            //                 }

            //             }

            //         }
            //     });



            // });




            // // 监听时钟滴答事件
            // window.viewer.clock.onTick.addEventListener(function(clock) {
            //     var currentTime = clock.currentTime;
            //     const timestamp = Cesium.JulianDate.toDate(currentTime).getTime();

            //    // canAnimate	布尔值	true	可选指示 Clock#tick 是否可以提前时间。例如，如果正在缓冲数据，这可能是错误的。只有当 Clock#canAnimate 和 Clock#shouldAnimate 都为真时，时钟才会滴答作响。 shouldAnimate


            //     // 检查是否达到结束时间
            //     if (!isEventEnded && timestamp >= stopTimeFlage) {
            //         console.log('事件已结束');
            //         isEventEnded = true; // 设置事件结束标志

            //     }

            //     // 如果事件未结束且时间间隔满足条件，则执行统计逻辑
            //     if (!isEventEnded && (timestamp - lastUpdateTime >= throttleInterval)) {
            //         lastUpdateTime = timestamp; // 更新上次更新时间戳

            //         // 执行统计逻辑
            //         console.log('执行统计逻辑：', new Date(timestamp).toLocaleString());
            //         // ...（你的统计逻辑）
            //     }
            // });


            // let lastUpdateTime = 0; // 上次更新时间戳（毫秒）
            // const throttleInterval = 30 * 60 * 1000; // 节流间隔：30分钟

            // window.viewer.clock.onTick.addEventListener(function(clock) {

            //     // 获取当前时间（JulianDate 对象）
            //     const currentTime = clock.currentTime;

            //     // 将 JulianDate 转换为日期对象
            //     const date = Cesium.JulianDate.toDate(currentTime);

            //     // 将日期对象转换为时间戳（毫秒数）
            //     const timestamp = date.getTime();

            //     if (timestamp - lastUpdateTime >= throttleInterval) {
            //         lastUpdateTime = timestamp;

            //         // 这里的代码将每隔30分钟执行一次
            //         const entities = window.viewer.entities.values;


            //     // // 重置圈内实体数量统计
            //     // Object.values(circleCounts).forEach(count => count = 0);

            //     //     entities.forEach(function(entity) {
            //     //         if (entity.id.startsWith('point_')) {
            //     //             var position = entity.position.getValue(currentTime);
            //     //             if (position) {
            //     //                 let insideCircle = false;

            //     //                 // 检查每个圈
            //     //                 if (Cesium.Cartesian3.distance(position, circleCenter_400) <= circleRadius_400) {
            //     //                     if (Cesium.Cartesian3.distance(position, circleCenter_300) > circleRadius_300) {
            //     //                         entity.point.color = Cesium.Color.YELLOW;
            //     //                         circleCounts['400']++;
            //     //                         insideCircle = true;
            //     //                     } else {
            //     //                         // 如果在300圈内，则继续检查更小的圈
            //     //                         if (Cesium.Cartesian3.distance(position, circleCenter_300) <= circleRadius_300) {
            //     //                             if (Cesium.Cartesian3.distance(position, circleCenter_200) > circleRadius_200) {
            //     //                                 entity.point.color = Cesium.Color.PURPLE;
            //     //                                 circleCounts['300']++;
            //     //                                 insideCircle = true;
            //     //                             } else {
            //     //                                 // 如果在200圈内，则继续检查更小的圈
            //     //                                 if (Cesium.Cartesian3.distance(position, circleCenter_200) <= circleRadius_200) {
            //     //                                     if (Cesium.Cartesian3.distance(position, circleCenter_100) > circleRadius_100) {
            //     //                                         entity.point.color = Cesium.Color.GREEN;

            //     //                                         circleCounts['200']++;
            //     //                                         insideCircle = true;
            //     //                                     } else {
            //     //                                         // 如果在100圈内，则继续检查更小的圈
            //     //                                         if (Cesium.Cartesian3.distance(position, circleCenter_100) <= circleRadius_100) {
            //     //                                             if (Cesium.Cartesian3.distance(position, circleCenter_30) > circleRadius_30) {
            //     //                                                 entity.point.color = Cesium.Color.BLUE;
            //     //                                                 circleCounts['100']++;
            //     //                                                 insideCircle = true;
            //     //                                             } else {
            //     //                                                 // 在30圈内
            //     //                                                 entity.point.color = Cesium.Color.RED;
            //     //                                                 circleCounts['30']++;
            //     //                                                 insideCircle = true;
            //     //                                             }
            //     //                                         } else {
            //     //                                             // 在100圈外但在其他圈内的情况已在上面的else中处理
            //     //                                         }
            //     //                                     }
            //     //                                 } else {
            //     //                                     // 在200圈外但在其他圈内的情况已在上面的else中处理
            //     //                                 }
            //     //                             }
            //     //                         } else {
            //     //                             // 在300圈外但在其他圈内的情况已在上面的else中处理
            //     //                         }
            //     //                     }
            //     //                 } else {
            //     //                     // 如果不在400圈内，则标记为outside并设置默认颜色
            //     //                     entity.point.color = Cesium.Color.WHITE;
            //     //                     circleCounts['outside']++;
            //     //                 }

            //     //                 // 如果未在任何特定圈内标记，则也应视为outside（理论上这不会发生，因为上面已经处理了所有情况）
            //     //                 if (!insideCircle) {
            //     //                     circleCounts['outside']++;
            //     //                 }
            //     //             }
            //     //         }
            //     //     });


            //         // ...（你的统计逻辑，但只处理必要的实体或基于某种条件）
            //         console.log('统计结果：',dd++);

            //         // 注意：由于我们仍然遍历了所有实体，这可能在实体数量非常大时不够高效。
            //         // 更优的解决方案是跟踪哪些实体发生了变化，并只处理这些实体。
            //     }
            // });




            // if (Cesium.Cartesian3.distance(position, circleCenter_30) <= circleRadius_30) {
            //     // 如果点在圆内，改变颜色
            //     entity.point.color = Cesium.Color.RED;
            // } else {
            //     // 如果点不在圆内，恢复颜色
            //     entity.point.color = Cesium.Color.WHITE; // 或者其他默认颜色
            // }

            // 优化后统计鸽子
            // 初始化圈内实体数量统计对象
            // const circleCounts = {
            //     '400': 0,
            //     '300': 0,
            //     '200': 0,
            //     '100': 0,
            //     '30': 0,
            //     'outside': 0 // 用于统计不在任何圈内的实体数量
            // };
            // const _this = this;
            // window.viewer.clock.onTick.addEventListener(function(clock) {
            //     var currentTime = clock.currentTime;
            //     _this.EchelonTime = _this.CesiumDateTimeXFormatter(currentTime);
            //     var entities = window.viewer.entities.values;

            //     // 重置圈内实体数量统计
            //     Object.values(circleCounts).forEach(count => count = 0);

            //     entities.forEach(function(entity) {
            //         if (entity.id.startsWith('point_')) {
            //             var position = entity.position.getValue(currentTime);
            //             if (position) {
            //                 let insideCircle = false;

            //                 // 检查每个圈
            //                 if (Cesium.Cartesian3.distance(position, circleCenter_400) <= circleRadius_400) {
            //                     if (Cesium.Cartesian3.distance(position, circleCenter_300) > circleRadius_300) {
            //                         entity.point.color = Cesium.Color.YELLOW;
            //                         circleCounts['400']++;
            //                         insideCircle = true;
            //                     } else {
            //                         // 如果在300圈内，则继续检查更小的圈
            //                         if (Cesium.Cartesian3.distance(position, circleCenter_300) <= circleRadius_300) {
            //                             if (Cesium.Cartesian3.distance(position, circleCenter_200) > circleRadius_200) {
            //                                 entity.point.color = Cesium.Color.PURPLE;
            //                                 circleCounts['300']++;
            //                                 insideCircle = true;
            //                             } else {
            //                                 // 如果在200圈内，则继续检查更小的圈
            //                                 if (Cesium.Cartesian3.distance(position, circleCenter_200) <= circleRadius_200) {
            //                                     if (Cesium.Cartesian3.distance(position, circleCenter_100) > circleRadius_100) {
            //                                         entity.point.color = Cesium.Color.GREEN;

            //                                         circleCounts['200']++;
            //                                         insideCircle = true;
            //                                     } else {
            //                                         // 如果在100圈内，则继续检查更小的圈
            //                                         if (Cesium.Cartesian3.distance(position, circleCenter_100) <= circleRadius_100) {
            //                                             if (Cesium.Cartesian3.distance(position, circleCenter_30) > circleRadius_30) {
            //                                                 entity.point.color = Cesium.Color.BLUE;
            //                                                 circleCounts['100']++;
            //                                                 insideCircle = true;
            //                                             } else {
            //                                                 // 在30圈内
            //                                                 entity.point.color = Cesium.Color.RED;
            //                                                 circleCounts['30']++;
            //                                                 insideCircle = true;
            //                                             }
            //                                         } else {
            //                                             // 在100圈外但在其他圈内的情况已在上面的else中处理
            //                                         }
            //                                     }
            //                                 } else {
            //                                     // 在200圈外但在其他圈内的情况已在上面的else中处理
            //                                 }
            //                             }
            //                         } else {
            //                             // 在300圈外但在其他圈内的情况已在上面的else中处理
            //                         }
            //                     }
            //                 } else {
            //                     // 如果不在400圈内，则标记为outside并设置默认颜色
            //                     entity.point.color = Cesium.Color.WHITE;
            //                     circleCounts['outside']++;
            //                 }

            //                 // 如果未在任何特定圈内标记，则也应视为outside（理论上这不会发生，因为上面已经处理了所有情况）
            //                 if (!insideCircle) {
            //                     circleCounts['outside']++;
            //                 }
            //             }
            //         }
            //     });

            //     // 输出每个圈内的数量到控制台（或用于其他目的）
            //     // console.log("输出每个圈内的数量到控制台（或用于其他目的）",circleCounts);
            // });

            // 优化统计鸽子2
            // const _this = this;

            // // 用于跟踪鸽子当前状态的映射
            // const pigeonStatus = {};

            // // 初始化每个圈的鸽子数量统计
            // const circleCounts = {
            //     '400': 0,
            //     '300': 0,
            //     '200': 0,
            //     '100': 0,
            //     '30': 0
            // };

            // window.viewer.clock.onTick.addEventListener(function(clock) {
            //     var currentTime = clock.currentTime;
            //     var entities = window.viewer.entities.values;

            //     _this.EchelonTime = _this.CesiumDateTimeXFormatter(currentTime);

            //     entities.forEach(function(entity) {
            //         if (entity.id.startsWith('point_')) {
            //             var pigeonId = entity.id;
            //             var position = entity.position.getValue(currentTime);

            //             if (position) {
            //                 var currentCircle = null;

            //                 // 检查鸽子当前所在的圈
            //                 if (Cesium.Cartesian3.distance(position, circleCenter_400) <= circleRadius_400
            //                     && (Cesium.Cartesian3.distance(position, circleCenter_300) > circleRadius_300 || !pigeonStatus[pigeonId])) {
            //                     currentCircle = '400';
            //                 } else if (Cesium.Cartesian3.distance(position, circleCenter_300) <= circleRadius_300
            //                     && (Cesium.Cartesian3.distance(position, circleCenter_200) > circleRadius_200 || !pigeonStatus[pigeonId])) {
            //                     currentCircle = '300';
            //                 } else if (Cesium.Cartesian3.distance(position, circleCenter_200) <= circleRadius_200
            //                     && (Cesium.Cartesian3.distance(position, circleCenter_100) > circleRadius_100 || !pigeonStatus[pigeonId])) {
            //                     currentCircle = '200';
            //                 } else if (Cesium.Cartesian3.distance(position, circleCenter_100) <= circleRadius_100
            //                     && (Cesium.Cartesian3.distance(position, circleCenter_30) > circleRadius_30 || !pigeonStatus[pigeonId])) {
            //                     currentCircle = '100';
            //                 } else if (Cesium.Cartesian3.distance(position, circleCenter_30) <= circleRadius_30) {
            //                     currentCircle = '30';
            //                 }

            //                 // 更新鸽子的颜色和状态
            //                 if (currentCircle) {
            //                     // 根据当前圈设置颜色
            //                     switch (currentCircle) {
            //                         case '400':
            //                             entity.point.color = Cesium.Color.YELLOW;
            //                             break;
            //                         case '300':
            //                             entity.point.color = Cesium.Color.PURPLE;
            //                             break;
            //                         case '200':
            //                             entity.point.color = Cesium.Color.GREEN;
            //                             break;
            //                         case '100':
            //                             entity.point.color = Cesium.Color.BLUE;
            //                             break;
            //                         case '30':
            //                             entity.point.color = Cesium.Color.RED;
            //                             break;
            //                         default:
            //                             entity.point.color = Cesium.Color.WHITE; // 或者其他默认颜色
            //                     }

            //                     // 更新鸽子的状态
            //                     pigeonStatus[pigeonId] = currentCircle;

            //                     // 更新圈的鸽子数量统计
            //                     circleCounts[currentCircle]++;

            //                     // 如果鸽子之前在其他圈中，减少那个圈的数量
            //                     if (pigeonStatus[pigeonId] !== currentCircle && pigeonStatus[pigeonId] in circleCounts) {
            //                         circleCounts[pigeonStatus[pigeonId]]--;
            //                     }
            //                 } else {
            //                     // 如果鸽子不在任何圈中，重置其颜色并清除状态
            //                     entity.point.color = Cesium.Color.WHITE;
            //                     if (pigeonStatus[pigeonId]) {
            //                         circleCounts[pigeonStatus[pigeonId]]--;
            //                         delete pigeonStatus[pigeonId];
            //                     }
            //                 }

            //                 // 打印或处理圈的鸽子数量统计（可选）
            //                 console.log('Circle Counts:', circleCounts);
            //             }
            //         }
            //     });
            // });


            // 确保时间轴更新
            // window.viewer.timeline.updateFromClock();
            // window.viewer.timeline.zoomTo(window.viewer.clock.startTime, window.viewer.clock.stopTime);

            // // 最小缩放高度（米）
            // window.viewer.scene.screenSpaceCameraController.minimumZoomDistance = 500;
            // // 最大缩放高度（米）
            // window.viewer.scene.screenSpaceCameraController.maximumZoomDistance = 500000;

            // 鼠标滚轮控制视角
            window.viewer.scene.screenSpaceCameraController.tiltEventTypes = [Cesium.CameraEventType.RIGHT_DRAG];

        },

        // TODO 封装方法 绘制贴地线
        createGroundLineAndBillboards(startPosition, endPosition) {
            // 创建贴地线
            var instance = new Cesium.GeometryInstance({
                geometry: new Cesium.GroundPolylineGeometry({
                    positions: [
                        startPosition,
                        endPosition
                    ],
                    width: 4.0
                }),
                id: 'object returned when this instance is picked and to get/set per-instance attributes'
            });

            window.viewer.scene.groundPrimitives.add(new Cesium.GroundPolylinePrimitive({
                geometryInstances: instance,
                appearance: new Cesium.PolylineMaterialAppearance({
                    material: Cesium.Material.fromType('#FF00ff')
                })
            }));

            // 设置视野
            //这是让你的画面以一个怎样的形式出现，相当于出场动画
            window.viewer.camera.flyTo({
                //   lon: 116.405419,
                // lat: 39.918034,
                // fromDegrees()方法，将经纬度和高程转换为世界坐标，这里定位到中国  101.8, 33.74,  121.795693, 25.146142
                //                     heading-代表镜头左右方向,正值为右,负值为左,360度和0度是一样的
                // pitch-代表镜头上下方向,正值为上,负值为下.
                // roll-代表镜头左右倾斜.正值,向右倾斜,负值向左倾斜、
                // Cesium.Cartesian3.fromDegreesArrayHeights( [this.startLng, this.startLat,5000,this.endLng,  this.endLat, 5000]),
                //   let startPosition = Cesium.Cartesian3.fromDegrees( this.startLng, this.startLat);
                // let endPosition = Cesium.Cartesian3.fromDegrees(this.endLng,  this.endLat);

                destination: Cesium.Cartesian3.fromDegrees(this.startLng, this.startLat, 500000),
                // orientation: {
                //     // 指向 z 180
                //     // heading:Cesium.Math.toRadians(-10),
                //     // 视角 y
                //     pitch:Cesium.Math.toRadians(-90),
                //     // 视角 x
                //     roll: -3.0,
                // },
                duration: 1,	// 以秒为单位的飞行持续时间。
                offset: {
                    heading: Cesium.Math.toRadians(0.0),	// 以弧度为单位的航向角。
                    pitch: -Math.PI / 2,					// 以弧度为单位的俯仰角。
                    range: 10								// 到中心的距离，以米为单位。								
                }
            });

            // 在起点添加广告牌
            window.viewer.entities.add({
                position: startPosition,
                // billboard: {
                //     image: require('@/assets/bike_online.png'),
                //     scale : 1.5,       // 大小
                //     // alignedAxis: Cesium.Cartesian3.UNIT_Z, // 设置广告牌始终面向相机
                //     heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考

                //     // sizeInMeters: true,  // 大小是否以米为单位
                //     horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // 水平方向
                //     verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // 垂直方向
                //     // pixelOffset: new Cesium.Cartesian2(35, 0), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平方向向右偏移35像素
                //     // alignedAxis: new Cesium.Cartesian3(-1,-10,-1) // 旋转轴 一般不用
                //     // rotation: 1.5, // 旋转角度 3.14  顺时针旋转3.14 = 360度
                //     // scaleByDistance: new Cesium.NearFarScalar(100, 1, 5000, 0.1), // 远近效果
                //     // pixelOffsetScaleByDistance: new Cesium.NearFarScalar(100000, 25, 10000000, 100), //偏移时 远近效果
                //     // translucencyByDistance: new Cesium.NearFarScalar(1000000, 25, 10000, 0.1), // 近远效果 透明度
                //     // distanceDisplayCondition: new Cesium.DistanceDisplayCondition(2000, 1000000) // 显示距离 2000 显示，1000000 隐藏
                // },
                // 模型数据（可以为每个轨迹加载不同的模型，如果需要的话）
                model: {
                    uri: './mapGLB/start2.glb', // 可以根据index加载不同的模型，例如：modelGltfArray[index]
                    // scale: 1, // 
                    minimumPixelSize: 128,  // 最小的模型像素
                    maximumScale: 200,
                    //   runAnimations: false,//是否显示动画
                    // clampAnimations: true,//是否保持最后一针的动画
                    heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考
                    horizontalOrigin: Cesium.HorizontalOrigin.BOTTOM, // 水平方向
                    verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // 垂直方向

                    pixelOffset: new Cesium.Cartesian2(0, -180), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平
                    eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面 
                    runAnimations: false,//是否显示动画
                    // clampAnimations: true,//是否保持最后一针的动画


                },
                label: {
                    // text: '起点',
                    text: this.$t('computerLanguage.startPoint'),
                    heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考
                    scale: 1.5,       // 大小
                    style: Cesium.LabelStyle.FILL_AND_OUTLINE, // 样式 
                    fillColor: Cesium.Material.fromType('#00ff00'),
                    outlineColor: Cesium.Color.BLACK, // 轮廓线
                    outlineWidth: 2,    // // 轮廓线宽度
                    // showBackground: true, // 背景
                    // backgroundColor: Cesium.Color.BLUE,
                    pixelOffset: new Cesium.Cartesian2(0, -140), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平
                    eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面    
                }
            });


            // // 在终点添加广告牌
            window.viewer.entities.add({
                position: endPosition,
                // billboard: {
                //     image: require('@/assets/bike_online.png'),
                //     scale : 1.5,       // 大小
                //     // sizeInMeters: true,  // 大小是否以米为单位
                //     horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // 水平方向
                //     verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // 垂直方向
                //     heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考
                //     // pixelOffset: new Cesium.Cartesian2(35, 0), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平方向向右偏移35像素
                //     // alignedAxis: new Cesium.Cartesian3(-1,-10,-1) // 旋转轴 一般不用
                //     // rotation: 1.5, // 旋转角度 3.14  顺时针旋转3.14 = 360度
                //     // scaleByDistance: new Cesium.NearFarScalar(100, 1, 5000, 0.1), // 远近效果
                //     // pixelOffsetScaleByDistance: new Cesium.NearFarScalar(100000, 25, 10000000, 100), //偏移时 远近效果
                //     // translucencyByDistance: new Cesium.NearFarScalar(1000000, 25, 10000, 0.1), // 近远效果 透明度
                //     // distanceDisplayCondition: new Cesium.DistanceDisplayCondition(2000, 1000000) // 显示距离 2000 显示，1000000 隐藏
                // },
                model: {
                    uri: './mapGLB/end3.glb', // 可以根据index加载不同的模型，例如：modelGltfArray[index]
                    scale: 1, // 
                    minimumPixelSize: 128,  // 最小的模型像素
                    maximumScale: 200,
                    heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考
                    horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // 水平方向
                    verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // 垂直方向
                    pixelOffset: new Cesium.Cartesian2(0, -180), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平
                    eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面 
                    //   runAnimations: false,//是否显示动画
                    // clampAnimations: true,//是否保持最后一针的动画

                },
                label: {
                    // text: '终点',
                    text: this.$t('computerLanguage.endPoint'),
                    scale: 1.5,       // 大小
                    style: Cesium.LabelStyle.FILL_AND_OUTLINE, // 样式 
                    fillColor: Cesium.Color.RED,
                    outlineColor: Cesium.Color.BLACK, // 轮廓线
                    outlineWidth: 2,    // // 轮廓线宽度
                    heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考
                    // showBackground: true, // 背景
                    // backgroundColor: Cesium.Color.BLUE,
                    pixelOffset: new Cesium.Cartesian2(0, -140), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平
                    eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面    
                }
            });
        },
        // TODO 方法 绘制圈
        createCircleEntity(position, radius, id, color) {
            var circleEntity = window.viewer.entities.add({
                id: id,
                position: position, // 设置圆心位置
                name: id, // 使用ID作为实体名称
                ellipse: {
                    semiMinorAxis: radius, // 设置椭圆的短半轴，与长半轴相等，形成圆
                    semiMajorAxis: radius, // 设置椭圆的长半轴
                    extrudedHeight:1000, // 设置高度
                    // height: 0, // 设置高度为0，使其贴合地面
                    material: Cesium.Color.fromCssColorString('#ffffff00'),// 设置填充颜色为绿色但完全透明

                    outline: true, // 启用外边框
                    // 可以根据需要设置outlineColor和outlineWidth，或者作为额外参数传入
                    outlineColor: Cesium.Color.fromCssColorString(color), // 默认设置外边框颜色为红色
                    outlineWidth: 2 // 默认设置外边框宽度为2
                }
            });
            return circleEntity; // 返回创建的实体对象，以便后续操作（可选）
        },

        // TODO 方法 隐藏显示设备
        hideShowDevice(deviceImeiArray) {

            // 获取所有以'point_'开头的实体
            var pointEntities = window.viewer.entities.values.filter(function (entity) {
                return entity.id.startsWith('point_');
            });

            // 先把所有的隐藏
            pointEntities.forEach(entity => {
                entity.show = false;
            });


            // 确保传入的数组有效
            if (!Array.isArray(deviceImeiArray) || deviceImeiArray.length === 0) {
                // console.warn('传入的设备号数组无效或为空');
                return;
            }



            // 创建一个映射，用于快速查找实体是否存在于设备号数组中
            const imeiToEntityMap = {};
            pointEntities.forEach(entity => {
                const imei = entity.id.split('_')[1]; // 假设id格式为'point_IMEI'
                imeiToEntityMap[imei] = entity;
            });
            // 
            // 判断是否只有一个设备，则显示设备
            if (deviceImeiArray.length === 1) {
                deviceImeiArray.forEach(imei => {
                    const entity = imeiToEntityMap[imei];
                    if (entity) {
                        entity.show = true;
                        // 如果实体存在，显示它（例如，改变标签颜色为红色，并跟踪它）
                        window.viewer.trackedEntity = entity;
                        // entity.label.fillColor = Cesium.Color.RED;
                        // console.log('显示设备号: ' + imei);

                        window.viewer.trackedEntity = entity; // 可以考虑是否每次都要设置trackedEntity
                    } else {
                        // 如果实体不存在，理论上应该隐藏它，但由于我们没有直接隐藏实体的方法，
                        // 我们可以选择不设置它为trackedEntity，或者如果有标签，将其颜色设置为不可见或白色
                        // console.warn('未找到设备号: ' + imei + ' 的实体');
                    }
                });
            } else {

                // 遍历设备号数组，处理每个设备号
                deviceImeiArray.forEach(imei => {
                    const entity = imeiToEntityMap[imei];
                    if (entity) {
                        entity.show = true;

                        // // 如果实体存在，显示它（例如，改变标签颜色为红色，并跟踪它）
                        // if (entity.label) {

                        //     // entity.label.fillColor = Cesium.Color.RED;
                        //     console.log('显示设备号: ' + imei);
                        // }
                        // window.viewer.trackedEntity = entity; // 可以考虑是否每次都要设置trackedEntity
                    } else {
                        // 如果实体不存在，理论上应该隐藏它，但由于我们没有直接隐藏实体的方法，
                        // 我们可以选择不设置它为trackedEntity，或者如果有标签，将其颜色设置为不可见或白色
                        // console.warn('未找到设备号: ' + imei + ' 的实体');
                    }
                });
            }

        },
        //TODO 移除，隐藏 所有鸽子实体 
        removeAllEntity() {
            // 获取所有以'point_'开头的实体
            var pointEntities = window.viewer.entities.values.filter(function (entity) {
                return entity.id.startsWith('point_');
            });

            // 如果没有找到任何实体，则退出函数
            if (pointEntities.length === 0) {
                // console.log("没有找到以'point_'开头的实体");
                return;
            }
            // 删除所有实体 用foer循环删除
            // pointEntities.forEach(function (entity) {
            //      window.viewer.entities.remove(entity);
            // });
            pointEntities.forEach(entity => {
                // window.viewer.entities.remove(entity);  // 删除所有实体
                entity.show = false;         // 隐藏所有实体
            });
            // for (var i = 0; i < pointEntities.length; i++) {
            //     window.viewer.entities.remove(pointEntities[i]);
            // }

        },


        // 全选 显示所有实体
        showAllEntity() {
            // 获取所有以'point_'开头的实体
            var pointEntities = window.viewer.entities.values.filter(function (entity) {
                return entity.id.startsWith('point_');
            });

            // 如果没有找到任何实体，则退出函数
            if (pointEntities.length === 0) {
                // console.log("没有找到以'point_'开头的实体");
                return;
            }
            // 删除所有实体 用foer循环删除
            pointEntities.forEach(function (entity) {
                entity.show = true;         // 显示所有实体
            });

        },
        // TODO  优化 不行 绘制广告牌
        // 起点经度纬度，和终点经度纬度，和圆的中心点经度纬度，和圆的半径，找出圆边和线的相交点绘制出广告牌 30公里
        createIntersectionBillboards(startLon, startLat, endLon, endLat, circleLon, circleLat, radiusInKm) {
            // 将经纬度转换为笛卡尔坐标
            function cartographicToCartesian(lon, lat, height = 0) {
                const cartographic = Cesium.Cartographic.fromDegrees(lon, lat, height);
                return Cesium.Ellipsoid.WGS84.cartographicToCartesian(cartographic);
            }

            // 计算线段与圆的相交点
            function lineCircleIntersection(lineStart, lineEnd, circleCenter, radius) {
                const direction = Cesium.Cartesian3.subtract(lineEnd, lineStart, new Cesium.Cartesian3());
                Cesium.Cartesian3.normalize(direction, direction);

                const toCenter = Cesium.Cartesian3.subtract(circleCenter, lineStart, new Cesium.Cartesian3());
                const a = Cesium.Cartesian3.dot(direction, direction);
                const b = 2.0 * Cesium.Cartesian3.dot(direction, toCenter);
                const c = Cesium.Cartesian3.dot(toCenter, toCenter) - radius * radius;

                const discriminant = b * b - 4 * a * c;

                const intersections = [];
                if (discriminant >= 0) {
                    const t1 = (-b - Math.sqrt(discriminant)) / (2.0 * a);
                    const t2 = (-b + Math.sqrt(discriminant)) / (2.0 * a);

                    if (t1 >= 0 && t1 <= 1) {
                        intersections.push(Cesium.Cartesian3.add(lineStart, Cesium.Cartesian3.multiplyByScalar(direction, t1, new Cesium.Cartesian3())));
                    }
                    if (t2 >= 0 && t2 <= 1) {
                        intersections.push(Cesium.Cartesian3.add(lineStart, Cesium.Cartesian3.multiplyByScalar(direction, t2, new Cesium.Cartesian3())));
                    }
                }

                return intersections;
            }

            // // 将笛卡尔坐标转换为经纬度
            // function cartesianToCartographic(cartesian) {
            //     return Cesium.Ellipsoid.WGS84.cartesianToCartographic(cartesian);
            // }

            // 转换经纬度
            const startCartesian = cartographicToCartesian(startLon, startLat);
            const endCartesian = cartographicToCartesian(endLon, endLat);
            const circleCartesian = cartographicToCartesian(circleLon, circleLat);

            // 半径转换为米
            const radius = radiusInKm; // * 1000;

            // 计算相交点
            const intersections = lineCircleIntersection(startCartesian, endCartesian, circleCartesian, radius);

            console.log("计算相交点:", intersections);

            // TODO zheli 在相交点处添加广告牌
            intersections.forEach((intersection, index) => {
                const position = Cesium.Ellipsoid.WGS84.cartesianToCartographic(intersection);
                window.viewer.entities.add({
                    position: Cesium.Cartesian3.fromDegrees(Cesium.Math.toDegrees(position.longitude), Cesium.Math.toDegrees(position.latitude)),
                    // billboard: {
                    //     image: require('@/assets/your-image.png'), // 使用 Vue 的 require 语法来加载本地图片
                    //     verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                    //     horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
                    // },
                    id: `billboard_${index}`,
                    label: {
                        text: '123',
                        // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考
                        // scale : 1.5,       // 大小
                        // style: Cesium.LabelStyle.FILL_AND_OUTLINE, // 样式 
                        // fillColor: Cesium.Material.fromType('#00ff00'), 
                        // outlineColor: Cesium.Color.BLACK, // 轮廓线
                        // outlineWidth: 2,    // // 轮廓线宽度
                        // // showBackground: true, // 背景
                        // // backgroundColor: Cesium.Color.BLUE,
                        // pixelOffset: new Cesium.Cartesian2(0, -50), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平
                        // eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面    
                    }
                });
            });

        },

        // 随机颜色
        getRandomColor() {
            var str = '#';
            //一个十六进制的值的数组
            var arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];
            for (var i = 0; i < 6; i++) {
                var num = parseInt(Math.random() * 16); //随机产生0-15之间的一个数
                str += arr[num]; //产生的每个随机数都是一个索引,根据索引找到数组中对应的值,拼接到一起
            }
            return str;
        },
        // 添加轨迹
        addLine(vals, color) {
            // 遍历每个线的坐标点数组
            vals.forEach((val) => {
                let positions = [];
                // 遍历当前线的每个坐标点
                val.forEach((item) => {
                    // 将经纬度转换为Cesium的Cartesian3坐标
                    positions.push(Cesium.Cartesian3.fromDegrees(item.lng, item.lat, item.height));
                });

                // 在viewer中添加一个新的线实体
                window.viewer.entities.add({
                    polyline: {
                        positions: positions,
                        // 线宽
                        width: 5,
                        heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考 旋转时不会偏移
                        // 路线颜色，这里可以使用传入的color参数或继续保持随机颜色 color ? Cesium.Color.fromCssColorString(color) :
                        material: Cesium.Color.fromRandom(),
                        // 是否贴地
                        clampToGround: false,
                    },
                });
            });
        },

        // 位置信息  list 对象 异步等待执行
        // TODO 拼装鸽子数据 该高度
        async computeFlight(lists) {
            var properties = []; // 用于存储每个轨迹的SampledPositionProperty对象

            // 遍历外部数组，每个元素是一个包含轨迹点的内部数组
            for (var i = 0; i < lists.length; i++) {
                var property = new Cesium.SampledPositionProperty(); // 为当前轨迹创建一个新的SampledPositionProperty对象
                var list = lists[i]; // 当前轨迹的点集合

                // 遍历当前轨迹的点集合
                for (var z = 0; z < list.length; z++) {
                    var item = list[z];
                    // TODO 根据json 文件不一  time  和 gpsLocationTime   互相更换
                    // height  和  altitude
                    // "lng": 120.407847,
                    // "lat": 23.408993,
                    var thisTime = Cesium.JulianDate.fromDate(new Date(item.gpsLocationTime));
                    const height = (item?.altitude != null) ? (item.altitude + 1000) : 1000;
                    var position = Cesium.Cartesian3.fromDegrees(
                        item.lng,
                        item.lat,
                        height
                    );

                    // 添加每一个链接点的信息，到达的时间以及坐标位置
                    property.addSample(thisTime, position);
                }
                properties.push(property); // 将当前轨迹的SampledPositionProperty对象添加到数组中
            }
            return properties; // 返回包含所有轨迹SampledPositionProperty对象的数组
        },
        // 位置信息  list 数组
        computeFlightArrays(list) {
            var property = new Cesium.SampledPositionProperty();


            // 遍历数据 循环添加轨迹线
            for (let i = 0; i < list.length; i++) {
                const positions = []; // 创建一个空数组来存储Cartesian3对象
                for (let j = 0; j < list[i].length; j++) {
                    // 将每个经纬度对转换为Cartesian3对象，并添加到positions数组中
                    // const [lng, lat,time] = list[i][j][t];
                    var thisTime = Cesium.JulianDate.fromDate(new Date(list[i][j].time));
                    positions.push(Cesium.Cartesian3.fromDegrees(list[i][j].lng, list[i][j].lat, list[i][j].height));
                }
                // 添加每一个链接点的信息，到达的时间以及坐标位置
                property.addSample(thisTime, positions);
            }
            return property;
        },
        // 添加模型动画
        // 异步执行
        // TODO 主方法
        async modelAnimate(list, modelGltf) {
            //添加路线 直播效果不要绘制线条
            //   let color = this.getRandomColor();
            //   this.addLine(list,color);



            // console.log("第一只鸽子开始时间："+list[0][0].lng);

            //  TODO 开始时间 ，  应该要自定义开始时间？  eventStartTime: 0, //   开始时间
            //   eventEndTime: 0,  // 结束时间
            //   var date = new Date(list[0][0].time);
            //   var start = Cesium.JulianDate.fromDate(date); //获取第一个点的时间 
            // this.eventStartTime = window.sessionStorage.getItem('historicalTrackStartTime') || ''
            // this.eventEndTime 




            //   // 设置始时钟始时间
            //   window.viewer.clock.startTime = this.startTime.clone();
            //   // 设置时钟当前时间
            //   window.viewer.clock.currentTime = this.startTime.clone();
            //   // 时间速率，数字越大时间过的越快
            //   window.viewer.clock.multiplier = 1;
            //          调用 位置信息
            let properties = await this.computeFlight(list); // 注意这里变量名改为properties，因为返回的是数组
            // console.log("properties-->", properties);

            // if (properties.length === 0) {
            //     console.error('No flight paths found.');
            //     return;
            // }

            // 选择第一个轨迹的SampledPositionProperty对象
            // let firstProperty = properties[1];




            // 设置始终停止时间（基于第一个轨迹）
            // var times = firstProperty._property._times;
            // var stop = times[times.length - 1];

            // console.log("设置始终停止时间（基于第一个轨迹） ", times, stop);
            // 结束时间：
            // var stopdate = new Date(this.eventEndTime);
            // var stop = Cesium.JulianDate.fromDate(stopdate); //获取第一个点的时间 

            // window.viewer.clock.stopTime = this.stopTime.clone();

            // // 假设start已经在之前定义过了，否则你需要从firstProperty中获取startTime
            // // var start = times[0]; // 如果需要从第一个轨迹中获取开始时间的话

            // // 时间轴（使用第一个轨迹的开始和停止时间）
            // window.viewer.timeline.zoomTo(this.startTime, this.stopTime);

            // // 循环执行，到达终止时间，重新从起点时间开始
            // window.viewer.clock.clockRange = Cesium.ClockRange.LOOP_STOP;

            // 为每个轨迹创建一个实体（这里仅以第一个轨迹为例）
            properties.forEach((property, index) => {
                //    console.log("index-->", this.zz);
                let planeModel = window.viewer.entities.add({
                    id: "point_" + this.list[index][0].deviceImei,    // 标识符
                    // 和时间轴关联（可以使用每个轨迹自己的时间范围，这里以第一个轨迹的时间为例）
                    availability: new Cesium.TimeIntervalCollection([

                        new Cesium.TimeInterval({
                            start: this.startTime, // 可以根据需要设置为每个轨迹自己的开始时间
                            stop: this.stopTime, // 可以根据需要设置为每个轨迹自己的停止时间
                        }),
                    ]),
                    position: property, // 使用当前轨迹的SampledPositionProperty
                    // 根据所提供的速度计算模型的朝向
                    // orientation: new Cesium.VelocityOrientationProperty(property),
                    // // 模型数据（可以为每个轨迹加载不同的模型，如果需要的话）
                    // model: {
                    //     uri: modelGltf, // 可以根据index加载不同的模型，例如：modelGltfArray[index]
                    //     scale: 1,
                    //     minimumPixelSize: 128,  // 最小的模型像素
                    //     maximumScale: 20000,

                    // },
                    // 图片
                    //  billboard: {
                    //     image: require('@/assets/bike_online.png'),
                    //     scale : 1.5,       // 大小
                    //     // sizeInMeters: true,  // 大小是否以米为单位
                    //     horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // 水平方向
                    //     verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // 垂直方向
                    //     // pixelOffset: new Cesium.Cartesian2(35, 0), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平方向向右偏移35像素
                    //     // alignedAxis: new Cesium.Cartesian3(-1,-10,-1) // 旋转轴 一般不用
                    //     // rotation: 1.5, // 旋转角度 3.14  顺时针旋转3.14 = 360度
                    //     // scaleByDistance: new Cesium.NearFarScalar(100, 1, 5000, 0.1), // 远近效果
                    //     // pixelOffsetScaleByDistance: new Cesium.NearFarScalar(100000, 25, 10000000, 100), //偏移时 远近效果
                    //     // translucencyByDistance: new Cesium.NearFarScalar(1000000, 25, 10000, 0.1), // 近远效果 透明度
                    //     // distanceDisplayCondition: new Cesium.DistanceDisplayCondition(2000, 1000000) // 显示距离 2000 显示，1000000 隐藏

                    //     },
                    // 点
                    point: {
                        pixelSize: 10,
                        color: Cesium.Color.WHITE,
                        // outlineColor: Cesium.Color.BLUE,
                        // outlineWidth: 3,
                        // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考
                        horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // 水平方向
                        verticalOrigin: Cesium.VerticalOrigin.CENTER, // 垂直方向
                    },

                    // // 广告牌
                    // label:{
                    //     text: '123456',
                    //     scale : 1.5,       // 大小
                    //     style: Cesium.LabelStyle.FILL_AND_OUTLINE, // 样式 
                    //     fillColor: Cesium.Color.RED, 
                    //     outlineColor: Cesium.Color.BLACK, // 轮廓线
                    //     outlineWidth: 2,    // // 轮廓线宽度
                    //     // showBackground: true, // 背景
                    //     // backgroundColor: Cesium.Color.BLUE,
                    //     pixelOffset: new Cesium.Cartesian2(0, -50), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平
                    //     eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面    


                    //     // font: '20px Helvetica',
                    //     // font: '14px sans-serif',
                    //     // fillColor: Cesium.Color.RED, 
                    //     // outlineWidth: 2,
                    //     // outlineColor: Cesium.Color.BLACK
                    // },
                    // 线条
                    // path:{
                    //     show: true,
                    //     leadTime : 0, // 显示时间
                    //     trailTime : 15, // 尾迹时间
                    //     resolution : 1, // 时间分辨率
                    //     width : 2, // 宽度
                    //     material : new Cesium.PolylineGlowMaterialProperty({ // 材质
                    //         glowPower : 0.2,
                    //         color : Cesium.Color.RED, 
                    //     }),

                    // }
                    // path:{
                    //     leadTime: 0,
                    //     resolution:1,
                    //     material: new Cesium.PolylineGlowMaterialProperty({
                    //         color: Cesium.Color.WHITE,
                    //         glowPower: 0.2,

                    //     }),
                    //     width: 10
                    // }

                });


            });
        },
        //创建模型
        addModel(url, height) {
            window.viewer.entities.removeAll();

            const position = Cesium.Cartesian3.fromDegrees(
                104.03697, 34.43811,
                height
            );
            const heading = Cesium.Math.toRadians(135); // 指向
            const pitch = 0;    // 视角
            const roll = 0;     // 偏移
            const hpr = new Cesium.HeadingPitchRoll(heading, pitch, roll);
            const orientation = Cesium.Transforms.headingPitchRollQuaternion(
                position,
                hpr
            );

            const entity = window.viewer.entities.add({
                name: url,
                position: position,
                orientation: orientation, // 方向
                model: {
                    uri: url,
                    minimumPixelSize: 328,  // 最小的模型像素
                    maximumScale: 20000,
                    // runAnimations: false,//是否显示动画
                    // clampAnimations: true,//是否保持最后一针的动画
                    //裁剪 ok
                    // clippingPlanes : new Cesium.ClippingPlaneCollection({
                    //     planes: [
                    //         new Cesium.ClippingPlane(new Cesium.Cartesian3(0,0,1), 0),

                    //     ],
                    //     edgeWidth: 1,
                    //     edgeColor:  Cesium.Color.RED,
                    //     enabled: true,
                    //     edgeMaterial: new Cesium.PolylineOutlineMaterialProperty({
                    //         color: Cesium.Color.RED,
                    //         outerWidth:1,
                    //         outlineColor: Cesium.Color.BLACK,

                    //     }),
                    // }),

                },
            });

            window.viewer.trackedEntity = entity;
        },
   // cesium时钟时间格式化函数
   CesiumTimeFormatter(datetime, viewModel) {
            var julianDT = new Cesium.JulianDate();
            Cesium.JulianDate.addHours(datetime, 8, julianDT);
            var gregorianDT = Cesium.JulianDate.toGregorianDate(julianDT);
            let hour = gregorianDT.hour + "";
            let minute = gregorianDT.minute + "";
            let second = gregorianDT.second + "";
            return `${hour.padStart(2, "0")}:${minute.padStart(2, "0")} : ${second.padStart(2, "0")}`;
        },

        // cesium时钟日期格式化函数 年月日 改成 - - -
        CesiumDateFormatter(datetime, viewModel, ignoredate) {
            var julianDT = new Cesium.JulianDate();
            Cesium.JulianDate.addHours(datetime, 8, julianDT);
            var gregorianDT = Cesium.JulianDate.toGregorianDate(julianDT);
            return `${gregorianDT.year}-${gregorianDT.month}-${gregorianDT.day}`;
        },

        // cesium时间轴格式化函数 年月日时分
        CesiumDateTimeFormatter(datetime, viewModel, ignoredate) {
            var julianDT = new Cesium.JulianDate();
            Cesium.JulianDate.addHours(datetime, 8, julianDT);
            var gregorianDT = Cesium.JulianDate.toGregorianDate(julianDT);
            let hour = gregorianDT.hour + "";
            let minute = gregorianDT.minute + "";
            return `${gregorianDT.year}-${gregorianDT.month}-${gregorianDT.day}-${hour.padStart(2, "0")}:${minute.padStart(2, "0")}`;
        },

        // cesium时间轴格式化函数 年月日时分秒
        CesiumDateTimeXFormatter(datetime, viewModel, ignoredate) {

            var julianDT = new Cesium.JulianDate();
            Cesium.JulianDate.addHours(datetime, 8, julianDT);
            var gregorianDT = Cesium.JulianDate.toGregorianDate(julianDT);
            let hour = gregorianDT.hour + "";
            let minute = gregorianDT.minute + "";
            let second = gregorianDT.second + "";
            return `${gregorianDT.year}-${gregorianDT.month}-${gregorianDT.day}-${hour.padStart(2, "0")}:${minute.padStart(2, "0")}:${second.padStart(2, "0")}`;
        },
        // 按钮点击事件
        switchAnimation() { // TODO 切换实体
            // 获取所有鸽子
            //    var entities = window.viewer.entities.values;
            //         // console.log("---",currentTime);
            //         entities.forEach(function(entity) {
            //             // 只处理以'point_'开头的实体，假设这些是您想要监控的点
            //             if (entity.id.startsWith('point_')) {

            //                 var position = entity.position.getValue();
            //                 if(position)
            //                 console.log("切换模型---",position);
            //             }

            //         });

            // 获取所有以'point_'开头的实体
            var pointEntities = window.viewer.entities.values.filter(function (entity) {
                return entity.id.startsWith('point_') && entity.show;
            });

            // 如果没有找到任何实体，则退出函数
            if (pointEntities.length === 0) {
                // console.log("没有找到以'point_'开头的实体");
                return;
            }

            // 更新当前索引
            this.currentIndex = (this.currentIndex + 1) % pointEntities.length;

            // 获取当前选中的实体
            var targetEntity = pointEntities[this.currentIndex];


            // 切换到目标实体
            window.viewer.trackedEntity = targetEntity;


        },
        startAnimation() { // TODO 开始播放
            window.viewer.clock.shouldAnimate = true;

            // 获取所有以'point_'开头的实体
            var pointEntities = window.viewer.entities.values.filter(function (entity) {
                return entity.id.startsWith('point_') && entity.show;
            });

            // 如果没有找到任何实体，则退出函数
            if (pointEntities.length === 0) {
                // console.log("没有找到以'point_'开头的实体");
                return;
            }

            // 更新当前索引
            this.currentIndex = (this.currentIndex + 1) % pointEntities.length;

            // 获取当前选中的实体
            var targetEntity = pointEntities[this.currentIndex];


            // 切换到目标实体
            window.viewer.trackedEntity = targetEntity;

        },
        pauseAnimation() {   // 暂停播放
            window.viewer.clock.shouldAnimate = false;


        },
        resetAnimation() {  // TODO 重置播放

            window.viewer.clock.currentTime = Cesium.JulianDate.fromDate(new Date(this.eventStartTime));
            // 重置实体索引
            this.currentIndex = -1;

            // 重置排名数据
            this.processTableData();

            let currentTime = Cesium.JulianDate.fromDate(new Date(1699403520000)); // 中国时间 开始时间
            //  TODO 设置时钟当前时间 改了下
            // window.viewer.clock.currentTime = this.startTime.clone();
            // 设置指定时间  Cesium Viewer 的当前时间为这个 JulianDate
            window.viewer.clock.currentTime = currentTime.clone();


            // 跳到地图起点
            // 设置视野
            //这是让你的画面以一个怎样的形式出现，相当于出场动画
            window.viewer.camera.flyTo({
                destination: Cesium.Cartesian3.fromDegrees(this.startLng, this.startLat, 500000),
                // orientation: {
                //     // 指向 z 180
                //     // heading:Cesium.Math.toRadians(-10),
                //     // 视角 y
                //     pitch:Cesium.Math.toRadians(90),
                //     // 视角 x
                //     roll: -3.0,
                // },

                duration: 1, // 以秒为单位的飞行持续时间。
                offset: {
                    heading: Cesium.Math.toRadians(0.0),	// 以弧度为单位的航向角。
                    pitch: -Math.PI / 2,					// 以弧度为单位的俯仰角。
                    range: 10								// 到中心的距离，以米为单位。								
                }

            });

        },
        speedUpAnimation() {  // 加速播放
            window.viewer.clockViewModel.multiplier *= 2;
        },
        speedDownAnimation() {   // 减速播放
            window.viewer.clockViewModel.multiplier /= 2;
        },





    }

}
</script>

<style lang="less" scoped>
#container {

    width: 100%;
    height: 100%;
}

/* .amap-ctrl-list-layer{
    right: 1108px;
  } */



.botOtherBox {


    position: absolute;
    width: 100%;
    bottom: 30px;
    /* left: 300px; */
    /* height: 60px; */
    -webkit-box-align: center;
    -ms-flex-align: center;
    z-index: 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /* margin: 0 auto; */
    -ms-flex-wrap: wrap;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;

}

.main_flex {
    display: flex;
    flex-wrap: wrap;
}

.botOtherItem {
    padding: 8px 18px;
    background: rgba(0, 9, 18, .5);
    box-shadow: 0px 2px 9px 0px rgba(0, 33, 71, .75);
    border-radius: 4px;
    font-size: 24px;
    color: #fff;
    margin-right: 15px;
    line-height: 1;
    cursor: pointer;
    user-select: none;
}

.botOtherItem:hover {

    color: #1af2ff;

}




.input-card {
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: .25rem;
    width: 22rem;
    border-width: 0;
    border-radius: 0.4rem;
    box-shadow: 0 2px 6px 0 rgba(114, 124, 245, .5);
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 0.75rem 1.25rem;
}

.input-card .btn {

    margin-right: 1.2rem;
    width: 9rem;
}

.input-card .btn:last-child {
    margin-right: 0;
}

/** 排名样式 */
.playBox {
    background: rgba(0, 9, 18, 0.5);
    box-shadow: 0px 2px 9px 0px rgba(0, 33, 71, .75);
    border-radius: 4px;
    user-select: none;
    height: 42px;
    z-index: 99;
}

.main_flex_ar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

.playBoxItem {
    width: 146px;
    height: 42px;
    padding: 0 15px;
    font-size: 24px;
    color: #fff;
    cursor: pointer;
    line-height: 1;
    position: relative;
}

.playBoxItem:hover {
    color: #1af2ff;
}


.main_flex_be {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.imgBox {
    width: 31px;
    height: 31px;
    margin-right: 8px;
}

img {
    width: 100%;
    height: 100%;
}

.icons {
    font-size: 20px;
    transition: all .3s;
}

.el-icon {

    height: 1em;
    width: 1em;
    line-height: 1em;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    fill: currentColor;
    color: #fff;
    font-size: inherit;
}

.botBox {
    display: block;
    /* 改为block以使用绝对定位 */
    position: absolute;
    bottom: 100%;
    /* 初始位置在imgBox的下方，但我们将通过JS调整它 */
    left: 0;
    width: 146px;
    /* max-height不再需要，因为我们将使用v-if来控制显示和隐藏 */
    transition: all 0.3s;
    background: rgba(0, 9, 18, 0.5);
    box-shadow: 0px 2px 9px 0px rgba(0, 33, 71, 0.75);
    border-radius: 4px;
    font-size: 24px;
    color: #fff;
    overflow: hidden;
    /* 初始时隐藏，通过v-if控制 */
    /* 注意：由于我们使用了v-if，max-height属性不再需要 */
}

/* 添加过渡效果 */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
    {
    opacity: 0;
}

.botBtnItem {
    width: 100%;
    padding: 12px 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

}

.botBtnItem:hover {
    color: #1af2ff;
}


/* 梯队数据样式 */
.echelonBox.on {
    transform: translate(0);
}

.echelonBox {
    padding: 20px 30px 10px;
    width: 520px;
    background: rgba(0, 0, 0, .05);
    border-radius: 8px;
    font-size: 22px;
    color: #fff;
    position: fixed;
    top: 120px;
    right: 30px;
    transition: all .3s;
    transform: translate(200%);
    // user-select: none;
}

.el-icon {
    --color: inherit;
    height: 1em;
    width: 1em;
    line-height: 1em;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    fill: currentColor;
    color: var(--color);
    font-size: inherit;
}

.titBoxImg {
    width: 30px;
    height: 30px;
    margin-right: 15px;
}

.closePlay2 {
    position: absolute;
    right: -10px;
    top: -10px;
    cursor: pointer;
    font-size: 22px;
    width: 30px;
    height: 30px;
    background: #000;
    opacity: 0;
    border-radius: 50%;
    transition: all .3;
}


.main_flex_ar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

.infoBoxTit {
    margin-bottom: 20px;
    align-items: center;
    font-size: 28px;
    color: #fff;
    text-shadow: 0px 0px 10px #fff;
    line-height: 1;
}

.echelonItem {
    margin-bottom: 20px;
}

.main_flex_be {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.echelonTit {
    font-size: 20px;
    color: #fff;
}

.echelonNum {
    font-size: 28px;
    color: #fff;
}

.main_flex {
    display: flex;
    flex-wrap: wrap;
}

.homingTime {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 700;
}

.echelonCont {
    padding-right: 50px;
    width: 100%;
}

/* 赛鸽排名样式 */


.index_one {
    color: #fec412;

}

.index_two {
    color: #b4c0c7;

}

.index_three {
    color: #714e3a;

}


::v-deep {
    // .el-table th.el-table__cell {
    //     user-select: initial;
    //     }

    .el-table,
    .el-table__expanded-cell {
        background-color: #ffffff00;
    }

    .el-table th.el-table__cell {
        background-color: #ffffff00;
    }

    .el-table thead {
        color: #ffffff;
    }

    .el-table tr {
        background-color: #ffffff00;
        color: #ffffff;
    }

    .el-table__empty-text {

        color: #ffffff;
    }

    //  .el-table--enable-row-hover .el-table__body tr:hover {
    //     background-color: #052531; 
    // }
    // 鼠标移上去
    .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
        background-color: #3498dbcb;
    }

    // // 设置滚动条颜色
    // .el-table__body-wrapper {
    //     scrollbar-width: thin; /* 或 'auto' */
    //     scrollbar-color: #00a0e9 #ffffff00; /* 滑块颜色 轨道颜色 */
    // }

    // .el-table--scrollable-y .el-table__body-wrapper ::-webkit-scrollbar {
    //     width: 10px; /*滚动条的宽*/
    //     height: 10px; /*滚动条的高*/
    // }
    // .el-table .el-table__body-wrapper::-webkit-scrollbar-track {
    //     box-shadow: 0px 1px 3px #0558f3 inset; /*滚动条的背景区域的内阴影*/
    //     border-radius: 10px; /*滚动条的背景区域的圆角*/
    //     background-color: #4a0735; /*滚动条的背景颜色*/
    // }




}


.el-table {
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
        width: 14px;
        /*滚动条宽度*/
        height: 14px;
        /*滚动条高度*/
    }

    /*定义滚动条轨道 内阴影+圆角*/
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
        box-shadow: 0px 1px 3px #071e4a inset;
        /*滚动条的背景区域的内阴影*/
        border-radius: 10px;
        /*滚动条的背景区域的圆角*/
        background-color: #ffffff00;
        /*滚动条的背景颜色*/
    }

    /*定义滑块 内阴影+圆角*/
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
        box-shadow: 0px 1px 3px #00a0e9 inset;
        /*滚动条的内阴影*/
        border-radius: 10px;
        /*滚动条的圆角*/
        background-color: #00a0e9;
        /*滚动条的背景颜色*/
    }
}
</style>