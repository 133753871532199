<template>
  <div id="app">
    <!-- 路由占位符 -->
     <!-- 缓存 所有页面 方法
      router-view

      include 缓存指定页面
      exclude 不缓存指定页面
      max 缓存页面最大数量
      <keep-alive include="home">

     -->
      <keep-alive include="pigeonRacingPlatform3D">
        <router-view></router-view>
        </keep-alive>

           <!-- <keep-alive>
        <router-view></router-view>
        </keep-alive> -->


        <!-- 指定缓存页面 -->
    <!-- <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>

    <router-view v-if="!$route.meta.keepAlive"></router-view> -->
   
       <!-- <keep-alive :max="2">
        <router-view></router-view>
        </keep-alive> -->

<!-- 路由占位符 -->
    <!-- <router-view></router-view> -->

  </div>
</template>

<script>
  // import moment from 'moment'

export default {
  name: 'app',
  // mounted() {
  //   moment.locale('en') // set to english
  //   // console.log(moment().format('YYYY-MM-DD HH:mm:ss'))
  //   // console.log("date", moment.locale('en')); // set to french)
  // },
}
</script>

<style>
</style>
