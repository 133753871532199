import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'

// 测试全局属性
// Vue.prototype.$myproperties = [];

import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import twLocale from 'element-ui/lib/locale/lang/zh-TW'



// import locale from 'element-ui/lib/locale'
import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
// 导入全局样式
import './assets/css/global.css'
// 导入字体图标
import './assets/fonts/iconfont.css'
// 导入表格树
import TreeTable from 'vue-table-with-tree-grid'
// 导入富文本编辑器
// import VueQuillEditor from 'vue-quill-editor'
// // 导入富文本编辑器样式
// import 'quill/dist/quill.core.css'
// import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'

import store from './store'
import VueI18n from 'vue-i18n'

// 导入NProgress, 包对应的JS和CSS
import NProgress from 'nprogress'
// import 'nprogress/nprogress.css'

// 导入百度地图
// import BaiduMap from 'vue-baidu-map'

// 以下为 vue2-google-map

import * as VueGoogleMaps from 'vue2-google-maps'
// 以下为 vue-google-map
// import 'vue-googlemaps/dist/vue-googlemaps.css'
// import VueGoogleMaps from 'vue-googlemaps'

import Vuex from 'vuex'

import axios from 'axios'
// 配置请求根路径
// 本机地址
axios.defaults.baseURL = 'http://localhost:8080'
// 远程后台地址
// axios.defaults.baseURL = 'http://api.globalgpstrace.com/webapi'
// axios.defaults.baseURL = 'http://track-api.gps866.com/webapi'
// axios.defaults.baseURL = 'http://localhost:8000/webapi'
// http://bbs.yanlianmeng.com:8088/jsonData/pigeon_5.json

// 在request 拦截器中, 展示进度条 NProgress.start()
// 请求在到达服务器之前，先会调用use中的这个回调函数来添加请求头信息
axios.interceptors.request.use(config => {
  NProgress.start()
  // console.log(config)
  // 为请求头对象，添加token验证的Authorization字段
  config.headers.Authorization = window.sessionStorage.getItem('token')
  // 在最后必须 return config
  return config
})
// response 拦截器中,  隐藏进度条NProgress.done()
axios.interceptors.response.use(config => {
  NProgress.done()
  return config
})

Vue.use(VueI18n)

Vue.use(Vuex)


// Vue.use(DatePicker)

// 挂在到Vue实例，后面可通过this调用
Vue.prototype.$http = axios

Vue.config.productionTip = false
// 组件全局注册 表格树
Vue.component('tree-table', TreeTable)
// 全局注册富文本编辑器
// Vue.use(VueQuillEditor)
// 全局注册百度地图
// Vue.use(BaiduMap, {
//   ak: 'Xs1A6W2NSujM4c6y0Vdn2Uz60LpG6a0W'
// })

// 以下为 vue2-google-map  guangzhou
// Vue.use(VueGoogleMaps, {
//   load: {
//     key: 'AIzaSyA2_mmxQzZppwme2aBEAJDOOqMhYrXwG40',
//     libraries: 'places'
//   },
//   installComponents: true
// })

// 缅甸key
// Vue.use(VueGoogleMaps, {
//   load: {
//     key: 'AIzaSyAOnpzvgvtrjn1XseFVM-sXIqWmqPXDjjw',
//     libraries: 'places'
//   },
//   installComponents: true
// })

// 非洲客户
// Vue.use(VueGoogleMaps, {
//   load: {
//     key: 'AIzaSyD4nD8N_LsIa0InTv5G1Pb9vERXlUIkoSQ',
//     libraries: 'places'
//   },
//   installComponents: true
// })

// 以下为vue-google-map
// Vue.use(VueGoogleMaps, {
//   load: {
//     apiKey: 'AIzaSyA2_mmxQzZppwme2aBEAJDOOqMhYrXwG40',
//     libraries: ['places'],
//     useBetaRenderer: false
//   }
// })

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCytwvScHFIsMQ2Sgx9aaBRxM4kTL6sQxo',
    libraries: 'places'
  },
  installComponents: true
})

const zhMessage = require('./common/lang/zh')
const twMessage = require('./common/lang/tw')
const enMessage = require('./common/lang/en')

const messages = {
  'zh-CN': {
    ...zhMessage,
    ...zhLocale
  },
  'zh-TW': {
    ...twMessage,
    ...twLocale
  },
  'zh': {
    ...zhMessage,
    ...zhLocale
  },
  'en-US': {
    ...enMessage,
    ...enLocale
  },
  'en': {
    ...enMessage,
    ...enLocale
  }
}

// const i18n = new VueI18n({
//   locale: store.state.lang, // 语言标识
//   messages: {
//     'zh-CN': require('./common/lang/zh'),
//     'en-US': require('./common/lang/en')
//   }
// })

const i18n = new VueI18n({
  locale: store.state.lang, // 语言标识
  messages
})

// locale.i18n((key, value) => i18n.t(key, value))

// Vue.use(ElementUI, { locale })

Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
})

// Vue.locale('zh-CN', zhLocale)
// Vue.locale('en-US', enLocale)

Vue.filter('dataFormat', function (originVal) {
  const dt = new Date(originVal)

  const y = dt.getFullYear()
  const m = (dt.getMonth() + 1 + '').padStart(2, '0')
  const d = (dt.getDate() + '').padStart(2, '0')

  const hh = (dt.getHours() + '').padStart(2, '0')
  const mm = (dt.getMinutes() + '').padStart(2, '0')
  const ss = (dt.getSeconds() + '').padStart(2, '0')
  // yyyy-mm-dd hh:mm:ss
  return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
})

// //定义一个新的Message方法
// let messageInstance = null;
// const $message = options => {
//     if(messageInstance) {
// 	//判断是否有提示框，有则关闭
//         messageInstance.close();
//     }
//     messageInstance = Message(options);
//     return messageInstance;
// };
 
// //重写方法
// ['success', 'warning', 'info', 'error'].forEach(type => {
//     $message[type] = options => {
//         if (typeof options === 'string') {
//             options = {
//                 message: options
//             };
//         }
//         options.type = type;
//         return ElementUI(options);
//     };
// });
// //将$message挂载到this上
// Vue.prototype.$message = $message;
// Vue.prototype.$message.closeAll = Message.closeAll;

// Vue.prototype.$success=function(message){
//   this.$notify({
//     title: 'success',
//     message: message,
//     type: 'success',
//     duration:1000,
//     position:'bottom-right',
//     showClose:false
//   })
// },
// Vue.prototype.$warning=function(message){
//   this.$notify({
//     title: 'warning',
//     message: message,
//     type: 'warning',
//     duration:1000,
//     position:'bottom-right',
//     showClose:false
//   })
// },
// Vue.prototype.$error=function(message){
//   this.$notify.error({
//     title: 'error',
//     message: message,
//     type:'error',
//     duration:3000,
//     position:'bottom-right',
//     showClose:false
//   })
// }

new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

export default Vue
