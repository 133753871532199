import request from '@/api/request'

/**
 * 查询赛事
 * @param {Integer} pageIndex 查询页数
 * @param {Integer} pageSize 查询个数
 */
export function queryRaceList(pwdType, pageIndex, pageSize) {
    const req = {
        action: 'gpsPigeonRaceAction',
        method: 'selectGpsPigeonRaceList',
        pwdType: 2,
        pageIndex: 1,
        pageSize: 65535
    }
    return returnGpsRequest(req)
}

/**
 * 查询赛事详情
 * @param {Integer} raceId 对应赛事 id 
 * @param {Integer} pageIndex 查询页数
 * @param {Integer} pageSize 查询个数
 */
export function queryRaceDetails(raceId, pageIndex, pageSize) {
    const req = {
        action: 'gpsPigeonRacehistoryAction',
        method: 'selectGpsPigeonRacehistoryList',
        pageIndex: 1,
        pageSize: 65535,
        data: {
            pigeonHouseId: raceId
        }
    }
    return returnGpsRequest(req)
}

/**
 * 插入赛事详情
 * @param {List} ringNoList 鸽环列表
 */
export function insertRaceDetails(ringNoList) {
    const req = {
        action: 'gpsPigeonRacehistoryAction',
        method: 'insertGpsPigeonRacehistory',
        data: ringNoList
    }
    return returnGpsRequest(req)
}

/**
 * 更新赛事详情
 * @param {Integer} ringId 鸽环ID
 * @param {Integer} ringNo 鸽环号
 * @param {String} deviceImei 设备IMEI
 */
export function updateRaceDetails(ringId, ringNo, deviceImei, electronicNo, remark) {
    const req = {
        action: 'gpsPigeonRacehistoryAction',
        method: 'updateGpsPigeonRacehistory',
        data: [{
            id: ringId,
            ringNo: ringNo,
            electronicRingNo: electronicNo,
            deviceImei: deviceImei,
            qualification: remark
        }]
    }
    return returnGpsRequest(req)
}

/**
 * 删除赛事详情
 * @param {List} ringNoList 鸽环ID列表
 */
export function deleteRaceDetails(ringNoList) {
    const req = {
        action: 'gpsPigeonRacehistoryAction',
        method: 'deleteGpsPigeonRacehistoryById',
        data: ringNoList
    }
    return returnGpsRequest(req)
}

/**
 * 查询鸽会
 */
export function queryPigeonGuildList() {
    const req = {
        action: 'gpsPigeonCommunityAction',
        method: 'selectGpsPigeonCommunityList',
        data: {
            communityName: ''
        }
    }
    return returnGpsRequest(req)
}

/**
 * 添加鸽会
 * @param {String} name 鸽会名
 * @param {String} contact 鸽会联系人
 * @param {String} tel 鸽会电话
 * @param {String} address 鸽会地址
 * @param {String} remark 备注
 */
export function insertPigeonGuild(name, contact, tel, address, remark) {
    const req = {
        action: 'gpsPigeonCommunityAction',
        method: 'insertGpsPigeonCommunity',
        data: {
            communityName: name,
            communityContact: contact,
            communityTel: tel,
            communityAddr: address,
            communityRemark: remark
        }
    }
    return returnGpsRequest(req)
}

/**
 * 修改鸽会信息
 * @param {Long} guildId 鸽会ID
 * @param {String} name 鸽会名
 * @param {String} contact 鸽会联系人
 * @param {String} tel 鸽会电话
 * @param {String} address 鸽会地址
 * @param {String} remark 备注
 */
export function updatePigeonGuild(guildId, name, contact, tel, address, remark) {
    const req = {
        action: 'gpsPigeonCommunityAction',
        method: 'updateGpsPigeonCommunity',
        data: {
            id: guildId,
            communityName: name,
            communityContact: contact,
            communityTel: tel,
            communityAddr: address,
            communityRemark: remark
        }
    }
    return returnGpsRequest(req)
}

/**
 * 删除鸽会
 * @param {Long} guildId 鸽会ID
 */
export function deletePigeonGuild(guildId) {
    const req = {
        action: 'gpsPigeonCommunityAction',
        method: 'deleteGpsPigeonCommunityById',
        data: [{
            id: guildId
        }]
    }
    return returnDefaultRequest(req)
}

/**
 * 查询鸽舍
 */
export function queryPigeonHouseList() {
    const req = {
        action: 'gpsPigeonHouseAction',
        method: 'selectGpsPigeonHouseList',
        data: {
            houseName: ''
        }
    }
    return returnGpsRequest(req)
}

/**
 * 添加鸽舍
 * @param {String} name 鸽舍名
 * @param {String} contact 鸽舍联系人
 * @param {String} tel 鸽舍电话
 * @param {String} address 鸽舍地址
 * @param {String} remark 备注
 */
export function insertPigeonHouse(name, contact, tel, address, remark) {
    const req = {
        action: 'gpsPigeonHouseAction',
        method: 'insertGpsPigeonHouse',
        data: {
            houseName: name,
            houseContact: contact,
            houseTel: tel,
            houseAddr: address,
            houseRemark: remark
        }
    }
    return returnGpsRequest(req)
}

/**
 * 修改鸽舍信息
 * @param {Long} houseId 鸽舍ID
 * @param {String} name 鸽舍名
 * @param {String} contact 鸽舍联系人
 * @param {String} tel 鸽舍电话
 * @param {String} address 鸽舍地址
 * @param {String} remark 备注
 */
export function updatePigeonHouse(houseId, name, contact, tel, address, remark) {
    const req = {
        action: 'gpsPigeonHouseAction',
        method: 'updateGpsPigeonCommunity',
        data: {
            id: houseId,
            houseName: name,
            houseContact: contact,
            houseTel: tel,
            houseAddr: address,
            houseRemark: remark
        }
    }
    return returnGpsRequest(req)
}

/**
 * 删除鸽舍
 * @param {Long} houseId 鸽舍ID
 */
export function deletePigeonHouse(houseId) {
    const req = {
        action: 'gpsPigeonHouseAction',
        method: 'deleteGpsPigeonHouseById',
        data: [{
            id: houseId
        }]
    }
    return returnGpsRequest(req)
}

/**
 * 注册经销商
 * @param {String} name 用户名
 * @param {String} pwd 密码
 * @param {Integer} roles 角色 1.鸽会 2.鸽舍
 * @param {Integer} parentId 鸽会id
 * @param {String} token 用户姓名
 * @param {String} mobile 手机号
 */
export function registerAgent(dataObj) {
    const req = {
        action: 'userAction',
        method: 'registByagent',
        parameter: dataObj['password'],
        data: dataObj
    }
    return returnDefaultRequest(req)
}

/**
 * 查询用户
 * @param {Integer} roles 角色 1.鸽会 2.鸽舍
 * @param {String} parentId 鸽会id
 */
export function queryRolesList(roles, parentId) {
    let type = ''
    let dataObj = []
    if (roles === 1) {
        type = '鸽会'
        dataObj = [{
            roles: type
        }]
    } else if (roles === 2) {
        type = '鸽舍'
        dataObj = [{
            roles: type,
            parentId: parentId
        }]
    }
    const req = {
        action: 'userAction',
        method: 'query',
        data: dataObj
    }
    return returnDefaultRequest(req)
}

/**
 * 删除经销商
 * @param {Long} agentId 经销商ID
 */
export function deleteAgent(agentId) {
    const req = {
        action: 'userAction',
        method: 'del',
        parameter: agentId
    }
    return returnDefaultRequest(req)
}

// /**
//  * 查询赛事绑定设备 注释了旧方法
//  * @param {Long} raceId 赛事ID 
//  */
// export function queryRaceDeviceList(raceId) {
//     const req = {
//         action: 'gpsDeviceAction',
//         method: 'selectGpsDeviceList',
//         data: [{
//             pigeonRaceId: raceId
//         }]
//     }
//     return returnGpsRequest(req)
// }


/**
 * TODO 查询赛事绑定设备 修改后的接口 2025-1-15
 * @param {Long} raceId 赛事ID
 */
export function queryRaceDeviceList(raceId) {
    const req = {
        action: 'gpsRacedeviceAction',
        method: 'selectGpsRacedeviceList',
        data: [{
            pigeonRaceId: raceId
        }]
    }
    return returnGpsRequest(req)
}


/**
 * 赛事绑定设备
 * @param {Long} raceId 赛事ID 解绑传null
 * @param {String} imei 设备Imei
 */
export function updateRaceDevice(raceId, imei) {
    if(raceId){
        const req = {
            action: 'gpsDeviceAction',
            method: 'updateGpsDevice',
            data: [{
                deviceImei: imei,
                pigeonRaceId: raceId,
                raceStatus: '1'
            }]
        }
        return returnGpsRequest(req)
    } else {
        const req = {
            action: 'gpsDeviceAction',
            method: 'updateGpsDevice',
            data: [{
                deviceImei: imei,
                raceStatus: '0'
            }]
        }
        return returnGpsRequest(req)
    }
}

/**
 * 查询赛事配置
 * @param {String} raceName 赛事名称
 */
export function queryRaceConfig(raceName) {
    const req = {
        action: 'deviceRaceconfigAction',
        method: 'queryByRace',
        parameter: raceName
    }
    return returnDefaultRequest(req)
}

/**
 * 更新赛事配置
 * @param {*} dataObj 配置对象
 */
export function updateRaceConfig(dataObj) {
    const req = {
        action: 'deviceRaceconfigAction',
        method: 'updateByRace',
        data: [dataObj]
    }
    return returnDefaultRequest(req)
}

/**
 * 获取默认参数的axios请求方法
 * @param {JSON} requestJson
 * @returns
 */
function returnDefaultRequest(requestJson) {
    return request({
        url: '/MainServlet',
        method: 'post',
        data: requestJson
    })
}

/**
 * 获取默认参数的axios请求方法
 * @param {JSON} requestJson
 * @returns
 * http://gps.gps866.com 之前的
 * http://bigpigeon.gps866.com:8078
 */
function returnGpsRequest(requestJson) {
    return request({
        url: '/MainServlet',
        method: 'post',
        requestBase: 'http://bigpigeon.gps866.com:8078',
       
        data: requestJson
    })
}