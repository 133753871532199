<template>
    <div style="height: 100%;">
        <!-- ˙直播平台页面 -->



        <!-- cesium 地图组件 123{{  list }} -->
        <div id="cesiumContainer" style="height: 100%; width: 100%;"></div>
        <div class="title" @click="saveFile">场景出图</div>

        <!-- TODO 刷新时间 -->
        <div class="timeClass">{{ $t('welcome.page.main.refreshPre') + second + $t('welcome.page.main.refreshNext') }}
        </div>




    </div>
</template>


<script>
// import Cesium from 'cesium/Cesium';
// import "@/Cesium/Widgets/widgets.css";
// import() "@/Cesium/Cesium.js";
// import 'cesium/Build/Cesium/Cesium.js';

// import * as Cesium from 'cesium';
// import { Cartesian3, createOsmBuildingsAsync, Ion, Math as CesiumMath, Terrain, Viewer } from 'cesium';

// 引入cesium样式文件
// 导入 Cesium 的样式文件
// import 'cesium/Build/Cesium/Widgets/widgets.css';
// // 导入 Cesium 的样式文件
// import 'cesium/Build/Cesium/Cesium.js';
// import "../../.././public/Cesium/Cesium.js";
// import "../../../Cesium/Widgets/widgets.css";
// import "/Cesium/Cesium.js";
// @import "../../../node_modules/cesium/Build/Cesium/Widgets/widgets.css";

// 引入ccs 适应手机屏幕 消除原有样式
import '@/assets/css/mywindow.css';
import moment from 'moment'
import 'moment-timezone';

// 8 ok
import TrajectoryData from '@/assets/data/pigeon_5.json';

// 15 ok


export default {
    setup() {
		const dataURLtoBlob = dataurl => {
			// dataurl   图片base64编码
			// filename  图片名
			// debugger
			let arr = dataurl.split(','),
				mime = arr[0].match(/:(.*?);/)[1],
				bstr = atob(arr[1]),
				n = bstr.length,
				u8arr = new Uint8Array(n)
			while (n--) {
				u8arr[n] = bstr.charCodeAt(n)
			}
			return new Blob([u8arr], { type: mime })
		}
		const saveFile = () => {
			window.viewer.render() //不加会出现导出是一张黑乎乎的图片
			let canvas = window.viewer.scene.canvas
			let image = canvas
				.toDataURL('image/png')
				.replace('image/png', 'image/octet-stream')
			let link = document.createElement('a')
			let blob = dataURLtoBlob(image)
			let objurl = URL.createObjectURL(blob)
			link.download = 'scene.png'
			link.href = objurl
			link.click()
		}
		return {
			dataURLtoBlob,
			saveFile,
		}
	},
    data() {
        return {
            cesiumLoaded: false, // cesium是否加载完成
            startTime: {},
            stopTime: {},
            currentTime: {},

            userLat: 0,  //用户纬度 latitude
            userLng: 0,  // 用户纬度 longitude 
            userTimezone: '',  // 用户时区

            intervalId: undefined, // 定时器id
            second: 5, // 刷新时间
            timerInterval: 5, // 刷新时间间隔
            positions: [], // 轨迹点

            test: 0, // 测试数据
            testData: [], // 测试数据
            piegonData: TrajectoryData, // TODO 本地数据测试
             additionalData : [
                
            ]
        };
    },
    // 要关闭的时候
    beforeDestroy() {
        // 销毁地图
        if (window.viewer) {
            // 移除监听器
            window.viewer.clock.onTick.removeEventListener(this.mOnTick);

            window.viewer.entities.removeAll(); // 移除所有实体
            window.viewer = null;

        }




    },
    created() {

        this.$i18n.locale = window.sessionStorage.getItem('lang')
        // this.userId = window.sessionStorage.getItem('userId')
        // this.userName = window.sessionStorage.getItem('userName')
        // this.pwdType = window.sessionStorage.getItem('pwdType')

        //  TODO 从缓存获取赛事名称 开始 和结束
        this.eventName = window.sessionStorage.getItem('historicalTrackDeviceImei') || '赛事名称'


        this.eventStartTime = window.sessionStorage.getItem('historicalTrackStartTime') || '2023-11-08 08:30:00'
        this.eventEndTime = window.sessionStorage.getItem('historicalTrackEndTime') || '2023-11-08 11:30:00'

        this.startLat = window.sessionStorage.getItem('historicalTrackStarLat') || '24.37612331771184'
        this.startLng = window.sessionStorage.getItem('historicalTrackStarLon') || '120.75020175398568'
        this.endLng = window.sessionStorage.getItem('historicalTrackEndLon') || '120.42185330498783'
        this.endLat = window.sessionStorage.getItem('historicalTrackEndLat') || '23.358101743572295'

        // console.log("开始时间：",new Date(this.eventStartTime),"结束时间：",new Date(this.eventEndTime))
        // 获取当前系统语言
        const language = navigator.language || navigator.userLanguage;
        // 判断语言
        let systemLanguage;
        if (language.startsWith('zh')) {
            // 判断是简体中文还是繁体中文
            if (language === 'zh-CN' || language === 'zh-Hans' || language === 'zh') {
                systemLanguage = 'zh-CN';
            } else if (language === 'zh-TW' || language === 'zh-Hant') {
                systemLanguage = 'zh-TW';
            } else {
                systemLanguage = 'en-US';
            }
        } else if (language.startsWith('en')) {
            systemLanguage = 'en-US';
        } else {
            systemLanguage = 'en-US';
        }

        // console.log('系统语言是:', systemLanguage);

        // 打印当前系统语言 systemLanguage
        // console.log("打印当前系统语言language", language);
        window.sessionStorage.setItem('lang', systemLanguage);
        // window.sessionStorage.setItem('lang', 'en-US');
        // window.sessionStorage.setItem('lang', 'zh-CN');

        // 从 sessionStorage 获取语言设置，如果没有则默认为 'zh-CN'  'en-US'    'zh-TW'
        const lang = window.sessionStorage.getItem('lang');
        console.log(" sessionStorage语言", lang);
        // 设置 Vue I18n 的当前语言
        this.$i18n.locale = lang;

        // 打印当前语言
        console.log("打印当前语言", this.$i18n.locale);




    },

    mounted() {
        // this.loadCesium(); // 加载Cesiumjs
        this.fetchLocation();   // 获取赛事位置
        let _this = this

        //TODO 计时器
        this.intervalId = setInterval(() => {
            _this.interval()
        }, 1000)



    },
    // 离开页面时
    beforeRouteLeave(to, from, next) {
        // TODO 清除计时器
        clearInterval(this.intervalId)
        next()
    },

    methods: {
        async loadCesium() {
      if (!this.cesiumLoaded) {
        // 创建一个新的 script 标签
        const script = document.createElement('script');
        script.src = '@/cesium/Build/Cesium/Cesium.js'; // 确保路径正确
        script.async = true;
        script.onload = () => {
          // Cesium.js 加载完成后执行的操作
          this.cesiumLoaded = true;
        //   this.initCesium();
        };
        script.onerror = (error) => {
          console.error('Failed to load Cesium.js:', error);
        };
        // 将 script 标签添加到 DOM 中
        document.head.appendChild(script);
      }
    },

        // 定时器方法
        interval() {

            //   if(this.queryDeviceList.length) {
            //     return
            //   }
            this.second--
            if (this.second < 1) {

                // 假设这是您要添加的全部数据（除了第一条已经存在的数据）
                // let additionalData = [
                // { deviceImei:'123456',lng: this.userLng, lat: this.userLat, height: 1000, gpsTime: '2024-01-04 11:25:00' },
                // { deviceImei:'123456',lng: 119.0, lat: 29.0, height: 1000, gpsTime: '2024-01-04 11:30:00' },
                //     { deviceImei:'123456',lng: 120.0, lat: 30.0, height: 1000, gpsTime: '2024-01-04 11:30:05' },
                //     { deviceImei:'123456',lng: 122.0, lat: 33.0, height: 2000, gpsTime: '2024-01-04 11:30:10' },
                //     { deviceImei:'123456',lng: 122.4, lat: 33.6, height: 3000, gpsTime: '2024-01-04 11:30:15' },
                //     { deviceImei:'123456',deviceImei:'123456',lng: 122.9, lat: 33.9, height: 4000, gpsTime: '2024-01-04 11:30:20' },
                //     // ... 可能有更多数据
                // ];

                // 初始化当前数组，只包含第一条数据
                // this.testData = [
                //     // { lng: this.userLng, lat: this.userLat, height: 1000, gpsTime: '2024-01-04 11:30:00' }
                // ];

                // 设置一个索引来追踪要添加的下一条数据
                // let indexToAdd = 0;

                // 设置定时器，每秒钟执行一次
                // let intervalId = setInterval(() => {
                    // 检查是否还有数据需要添加
                    if (this.test < this.additionalData[0].length) {
                        // 获取下一条要添加的数据
                        let newDataItem = this.additionalData[0][this.test];

                        // 将新数据添加到数组中
                        this.testData.push(newDataItem);

                        // 打印当前数组内容，以便观察（可选）
                        // console.log(this.test,this.testData);
                        console.log("打印当前数组内容，以便观察（可选",newDataItem);
                        console.log("打印当前数组内容，以便观察（可选", this.testData);
                        // this.updateTrajectory(newDataItem);
                    //    this.modelAnimate(this.testData,'./mapGLB/Cesium_Air.glb');

            //             let properties = this.computeFlight(this.testData); // 注意这里变量名改为properties，因为返回的是数组
            // console.log("properties-->", properties);

                        // 索引递增，以便下次添加下一条数据
                        this.test++;
                    } else {
                        // 如果没有更多数据需要添加，清除定时器
                        clearInterval(this.intervalId)
                        console.log('所有数据已添加完毕！');
                        // this.modelAnimate(this.additionalData,'./mapGLB/Cesium_Air.glb');
                    }
                // }, 1000); // 1000毫秒 = 1秒


                // const _this = this

                // for(let [key, value] of this.deviceMap) {
                //   if(value === '1') {
                //     this.queryDeviceList.push(key)
                //   }
                // }
                // this.startSearchTrackLog()
                // 发送请求 获取点位数据
                console.log('发送请求 获取点位数据')

                // // 重置倒计时时间
                this.second = this.timerInterval
            }
        },
        // 更新轨迹数据
        updateTrajectory(data) {

            // 如果没有数据，则不执行任何操作
            if (!data || data.length === 0) {
                return;
            }

            // 不需要清除旧轨迹点和重新添加实体，只需更新路径数据
            //   this.positions = []; // 清空当前位置数组以准备添加新数据

            // 添加新的轨迹点
            // data.forEach(point => {
            //     const cartesian3 = Cesium.Cartesian3.fromDegrees(
            //         point.lng,
            //         point.lat,
            //         point.height || 0 // 如果数据中没有高度信息，可以使用0作为默认值
            //     );
            //     this.positions.push(cartesian3);
            // });
           
                const cartesian3 = Cesium.Cartesian3.fromDegrees(
                    data.lng,
                    data.lat,
                    data.height || 0 // 如果数据中没有高度信息，可以使用0作为默认值
                );
                this.positions.push(cartesian3);
           

            console.log("this.positions", this.positions);
            // 创建用于显示轨迹的实体
            window.entity = window.viewer.entities.add({
                name: 'Device Trajectory'+this.test,
                position: this.positions,
                point: {
                    pixelSize: 10,
                    color: Cesium.Color.RED,
                },
                model: {
                    show: true,
                    uri: './mapGLB/Cesium_Air.glb', // 可以根据index加载不同的模型，例如：modelGltfArray[index]
                    // scale: 1, // 
                    minimumPixelSize: 128,  // 最小的模型像素
                    maximumScale: 2000,
                    //   runAnimations: false,//是否显示动画
                    // clampAnimations: true,//是否保持最后一针的动画

                },

                polyline: {
                    positions: this.positions,
                    // 线宽
                    width: 2,
                    heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考 旋转时不会偏移
                    // 路线颜色
                    material: Cesium.Color.fromCssColorString("#ffffff"),
                    // 是否贴地
                    clampToGround: false,
                },
                path: {
                    resolution: 2,
                    material: new Cesium.PolylineOutlineMaterialProperty({
                        color: Cesium.Color.RED.withAlpha(0.5),
                        outlineWidth: 2,
                        outlineColor: Cesium.Color.BLACK,
                    }),
                    width: 2,
                },
            });

            // 跳转视野
            // window.viewer.trackedEntity = window.entity;


            // 更新轨迹数据，而不是重新创建实体
            // if (window.entity.path) {
            //     window.entity.path.positions = this.positions; // 直接更新路径数据
            // } else {
            //     // 如果实体还没有路径，则添加路径组件
            //     window.entity.path = {
            //         resolution: 2,
            //         material: new Cesium.PolylineOutlineMaterialProperty({
            //             color: Cesium.Color.RED.withAlpha(0.5),
            //             outlineWidth: 2,
            //             outlineColor: Cesium.Color.BLACK,
            //         }),
            //         width: 2,
            //         positions: this.positions, // 初始设置路径数据
            //     };
            // }

            // 更新实体的位置为最后一个点（如果需要）
            if (this.positions.length > 0) {
                window.entity.position = this.positions[this.positions.length - 1];
            }
        },
          // TODO 主方法
          modelAnimate(list, modelGltf) {
            //添加路线 直播效果不要绘制线条
            //   let color = this.getRandomColor();
            //   this.addLine(list,color);
             // 3D 获取所有以'point_'开头的实体
             var excludePointEntities = window.viewer.entities.values.filter(function (entity) {
                        return entity.id.startsWith('point_');
                    });

                    console.log("excludePointEntities-->", excludePointEntities.length);
                    // 如果没有找到任何实体，则退出函数
                    if (excludePointEntities.length != 0) {

                        excludePointEntities.forEach(entity => {
                            window.viewer.entities.remove(entity);  // 删除实体
                            // entity.show = false;         // 隐藏所有实体
                        });
                    }

            //          调用 位置信息
            let properties = [];
             properties = this.computeFlight(list); // 注意这里变量名改为properties，因为返回的是数组
            console.log("properties-->", properties.length);
          
            console.log("propertiesid----00->",   this.additionalData[0][0].deviceImei,);
            // // 为每个轨迹创建一个实体（这里仅以第一个轨迹为例）
            properties.forEach((property, index) => {
                //    console.log("index-->", this.zz);
                let planeModel = window.viewer.entities.add({
                    // show: this.sendisHide,  // 是否显示
                    id: "point_" + this.additionalData[index][0].deviceImei,   // 标识符
                    // 和时间轴关联（可以使用每个轨迹自己的时间范围，这里以第一个轨迹的时间为例）
                    availability: new Cesium.TimeIntervalCollection([

                        new Cesium.TimeInterval({
                            start: this.startTime, // 可以根据需要设置为每个轨迹自己的开始时间
                            stop: this.stopTime, // 可以根据需要设置为每个轨迹自己的停止时间
                        }),
                    ]),
                    position: property, // 使用当前轨迹的SampledPositionProperty
                    // 根据所提供的速度计算模型的朝向
                    orientation: new Cesium.VelocityOrientationProperty(property),
                    // 模型数据（可以为每个轨迹加载不同的模型，如果需要的话）
                    model: {
                        uri: modelGltf, // 可以根据index加载不同的模型，例如：modelGltfArray[index]
                        // scale: 1, // 
                        minimumPixelSize: 128,  // 最小的模型像素
                        maximumScale: 2000,
                        //   runAnimations: false,//是否显示动画
                        // clampAnimations: true,//是否保持最后一针的动画

                    },
                    // 图片
                    //  billboard: {
                    //     image: require('@/assets/bike_online.png'),
                    //     scale : 1.5,       // 大小
                    //     // sizeInMeters: true,  // 大小是否以米为单位
                    //     horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // 水平方向
                    //     verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // 垂直方向
                    //     // pixelOffset: new Cesium.Cartesian2(35, 0), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平方向向右偏移35像素
                    //     // alignedAxis: new Cesium.Cartesian3(-1,-10,-1) // 旋转轴 一般不用
                    //     // rotation: 1.5, // 旋转角度 3.14  顺时针旋转3.14 = 360度
                    //     // scaleByDistance: new Cesium.NearFarScalar(100, 1, 5000, 0.1), // 远近效果
                    //     // pixelOffsetScaleByDistance: new Cesium.NearFarScalar(100000, 25, 10000000, 100), //偏移时 远近效果
                    //     // translucencyByDistance: new Cesium.NearFarScalar(1000000, 1, 10000, 0), // 近远效果 透明度
                    //     // distanceDisplayCondition: new Cesium.DistanceDisplayCondition(2000, 1000000) // 显示距离 2000 显示，1000000 隐藏

                    //     },
                    // 点
                    // point: {
                    //         pixelSize: 10,
                    //         color: Cesium.Color.WHITE,
                    //         // outlineColor: Cesium.Color.BLUE,
                    //         // outlineWidth: 3,
                    //             // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考
                    //             horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // 水平方向
                    //             verticalOrigin: Cesium.VerticalOrigin.CENTER, // 垂直方向
                    //             },

                    // // 广告牌
                    // 广告牌
                    // TODO 广告牌
                    label: {
                        text:  this.additionalData[0][0].deviceImei,  
                        scale: 1.5,       // 大小
                        font: '18px sans-serif',
                        // style: Cesium.LabelStyle.FILL_AND_OUTLINE, // 样式 
                        fillColor: Cesium.Color.WHITE,
                        // outlineColor: Cesium.Color.BLACK, // 轮廓线
                        // outlineWidth: 2,    // // 轮廓线宽度
                        // showBackground: true, // 背景
                        // backgroundColor: Cesium.Color.BLUE,
                        translucencyByDistance: new Cesium.NearFarScalar(10000, 1, 8000000, 0), // 近远效果 透明度
                        pixelOffset: new Cesium.Cartesian2(0, -100), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平
                        eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面    


                        // font: '20px Helvetica',
                        // font: '14px sans-serif',
                        // fillColor: Cesium.Color.RED, 
                        // outlineWidth: 2,
                        // outlineColor: Cesium.Color.BLACK
                    },
                    // label:{
                    //     text: '123456',
                    //     scale : 1.5,       // 大小
                    //     style: Cesium.LabelStyle.FILL_AND_OUTLINE, // 样式 
                    //     fillColor: Cesium.Color.RED, 
                    //     outlineColor: Cesium.Color.BLACK, // 轮廓线
                    //     outlineWidth: 2,    // // 轮廓线宽度
                    //     // showBackground: true, // 背景
                    //     // backgroundColor: Cesium.Color.BLUE,
                    //     pixelOffset: new Cesium.Cartesian2(0, -50), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平
                    //     eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面    


                    //     // font: '20px Helvetica',
                    //     // font: '14px sans-serif',
                    //     // fillColor: Cesium.Color.RED, 
                    //     // outlineWidth: 2,
                    //     // outlineColor: Cesium.Color.BLACK
                    // },
                    // 线条
                    // path:{
                    //     show: true,
                    //     leadTime : 0, // 显示时间
                    //     trailTime : 15, // 尾迹时间
                    //     resolution : 1, // 时间分辨率
                    //     width : 2, // 宽度
                    //     material : new Cesium.PolylineGlowMaterialProperty({ // 材质
                    //         glowPower : 0.2,
                    //         color : Cesium.Color.RED, 
                    //     }),

                    // }
                    // TODO 加了轨迹线就巨卡
                    path: {
                        leadTime: 0,
                        resolution: 120,
                        // material: new Cesium.ColorMaterialProperty(Cesium.Color.WHITE ),

                        material: new Cesium.ColorMaterialProperty(Cesium.Color.fromCssColorString("#FFFF00")),
                        width: 2
                    }

                });


            });
        },
        computeFlight(lists) {
            var properties = []; // 用于存储每个轨迹的SampledPositionProperty对象

            // 遍历外部数组，每个元素是一个包含轨迹点的内部数组
            for (var i = 0; i < lists.length; i++) {
                var property = new Cesium.SampledPositionProperty(); // 为当前轨迹创建一个新的SampledPositionProperty对象
                var list = lists[i]; // 当前轨迹的点集合
                // console.log("当前轨迹的点集合",list);
                // 遍历当前轨迹的点集合
                for (var z = 0; z < list.length; z++) {
                    var item = list[z];
                    // TODO 根据json 文件不一  time  和 gpsLocationTime   互相更换
                    // height  和  altitude
                    // "lng": 120.407847,
                    // "lat": 23.408993,  "gpsTime": "2023-11-08 11:06:35",
                    let MendTime = moment.tz(item.gpsTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Shanghai');

                    // var thisTime = Cesium.JulianDate.fromDate(new Date(item.gpsLocationTime));
                    var thisTime = Cesium.JulianDate.fromDate(MendTime.toDate());
                    // const height = (item?.altitude != null) ? (item.altitude + 1) : 1;
                    var position = Cesium.Cartesian3.fromDegrees(
                        item.lng,
                        item.lat,
                        item.height
                    );
                    // console.log("当前时间", thisTime,position);
                    // 添加每一个链接点的信息，到达的时间以及坐标位置
                    property.addSample(thisTime, position);
                }
                properties.push(property); // 将当前轨迹的SampledPositionProperty对象添加到数组中
            }
            return properties; // 返回包含所有轨迹SampledPositionProperty对象的数组
        },

        // TODO 第三方链接 获取ip地址位置信息
        async fetchLocation() {
            try {
                const response = await fetch(`https://ipinfo.io/json`);
                const data = await response.json();
                console.log("ip地址", data);
                // if (data.status !== 'success') {
                //   throw new Error('Failed to fetch location data.');
                // }
                const userLat = data.loc.split(',')[0];  // 用户纬度 latitude
                const userLng = data.loc.split(',')[1];  // 用户纬度 longitude
                const userTimezone = data.timezone;  // 用户时区

                // 存到本地缓存
                window.sessionStorage.setItem('userLat', userLat);
                window.sessionStorage.setItem('userLng', userLng);
                window.sessionStorage.setItem('userTimezone', userTimezone);

                // 初始化设置时钟开始时间
                this.initClock();

                // cesium 地图初始化
                this.initCesiumMap();


                //    console.log("用户纬度 latitude",this.userLat);
                //      console.log("用户纬度 longitude",this.userLng);
                //         console.log("用户时区",this.userTimezone);



            } catch (err) {
                this.error = 'Failed to fetch location.';

            }
        },

        // 初始化时间 设置为中国上海时间
        initClock() {
            // 获取世界时间当前上海的时间
            // 如果您需要日期对象，请保留原始的moment对象
            const shanghaiTimeObj = moment.tz('Asia/Shanghai'); // 当前时间moment对象
            const startTimeObj = moment.tz('Asia/Shanghai').startOf('day'); // 开始时间moment对象
            const endTimeObj = moment.tz('Asia/Shanghai').endOf('day'); // 结束时间moment对象

            // 打印日期对象（如果需要）
            console.log('当前上海时间对象:', shanghaiTimeObj.toDate());
            console.log('开始时间对象:', startTimeObj.toDate());
            console.log('结束时间对象:', endTimeObj.toDate());

            // let MendTime = moment.tz('2023-11-08 08:32:00', 'YYYY-MM-DD HH:mm:ss', 'Asia/Shanghai');
            // console.log("设置当前时间-->", MendTime.toDate());

            // 中国上海时间 开始时间
            this.startTime = Cesium.JulianDate.fromDate(startTimeObj.toDate());
            this.stopTime = Cesium.JulianDate.fromDate(endTimeObj.toDate());    // 结束时间
            this.currentTime = Cesium.JulianDate.fromDate(shanghaiTimeObj.toDate()); // 中国上海时间 当前时间
        },

        // TODO 初始化地图
        initCesiumMap() {

            var token = 'f180b8af3efcf798a9281dd0f0d3dcae';
    // 服务域名
    var tdtUrl = 'https://t{s}.tianditu.gov.cn/';
    // 服务负载子域
    var subdomains=['0','1','2','3','4','5','6','7'];


            (Cesium.Ion.defaultAccessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJmY2Q4ZjNhYy0yM2FhLTRlNmItOGE2OC00ZDA1MTVlNzYyNmMiLCJpZCI6MjUxMjMxLCJpYXQiOjE3MzA0NTIyMjh9.fWsd13QqoCAG-EtYrZFXt_4Wqsy4tmn-yJ4MEuMvIIg");
            // 备份自己的  eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJmY2Q4ZjNhYy0yM2FhLTRlNmItOGE2OC00ZDA1MTVlNzYyNmMiLCJpZCI6MjUxMjMxLCJpYXQiOjE3MzA0NTIyMjh9.fWsd13QqoCAG-EtYrZFXt_4Wqsy4tmn-yJ4MEuMvIIg
            // 詹总  eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI4YWFiNmJiMS1jNjRjLTRkM2QtYTgzOC05MTMwZDJjOGJmNzIiLCJpZCI6MjU2MDE3LCJpYXQiOjE3MzIwMTgzMDl9.N5fr5-Z7OGnVoirhJ-wA3NzQ4TFDidiw9cUzlQBZGKc
            //      eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJkYzUwYjE0NS04ZTQ0LTRkMWMtYTI4Yi1iOWFhNDMzNDY3ZTAiLCJpZCI6MjUxMjMxLCJpYXQiOjE3MzE4OTg0OTR9.mj5LfUew2Hw2PyB9Fctyv4_KzPXv4GnyvHDlTqaNODQ

            // 获取语言设置
            const lang = window.sessionStorage.getItem('lang');

            // 创建通用的 Viewer 配置对象
            const commonViewerConfig = {
                homeButton: true,
                sceneModePicker: false,  // 地图模式
                baseLayerPicker: false, // 影像切换
                animation: true, // 是否显示动画控件
                selectionIndicator: false, // 要素选中框
                geocoder: false, // 是否显示地名查找控件
                timeline: true, // 是否显示时间线控件
                fullscreenButton: true, // 是否显示全屏控件

                shouldAnimate: true, // 默认不播放动画
                infoBox: true, // 是否显示点击要素之后显示的信息
                navigationHelpButton: false, // 是否显示帮助信息控件
                
                navigationInstructionsInitiallyVisible: false, // 是否显示操作说明控件
                showRenderLoopErrors: false,    // 是否显示渲染循环错误信息
                // shadows: false, // 是否显示阴影 
                creditContainer: document.createElement("div"),
                // 最小缩放和地形设置
                terrain: Cesium.Terrain.fromWorldTerrain({
                    requestWaterMask: false, // 水面蒙版
                    requestVertexNormals: true // 
                })
            };


            if (lang !== 'en-US') { // 中文  Cesium.IonWorldImageryStyle.ROAD
                // 中文配置，叠加高德注记地图
                const chineseViewerConfig = {
                    ...commonViewerConfig,
                    // infoBox: true, // 中文环境下显示点击要素之后的信息
                };

                window.viewer = new Cesium.Viewer("cesiumContainer", chineseViewerConfig);

                // // 如果需要叠加高德/百度注记地图则添加以下代码
                // window.viewer.imageryLayers.addImageryProvider(new Cesium.UrlTemplateImageryProvider({
                //     url: "http://webst02.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scale=1&style=8",
                //     layer: "tdtAnnoLayer",
                //     style: "default",
                //     format: "image/jpeg",
                //     tileMatrixSetID: "GoogleMapsCompatible"
                // }))


                window.viewer.imageryLayers.addImageryProvider(new Cesium.WebMapTileServiceImageryProvider({
                url: "http://t0.tianditu.com/cva_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=cva&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default.jpg&tk="+token,
                    layer: "tdtAnnoLayer",
                    style: "default",
                    format: "image/jpeg",
                    tileMatrixSetID: "GoogleMapsCompatible"
                }));





    

                console.log("中文");
            } else { // 英文

                // 英文配置，使用英文标注的底图
                const englishViewerConfig = {
                    ...commonViewerConfig,
                    // infoBox: true, // 英文环境下也显示点击要素之后的信息
                    baseLayer: Cesium.ImageryLayer.fromWorldImagery({
                        style: Cesium.IonWorldImageryStyle.AERIAL_WITH_LABELS,
                    }),
                };

                window.viewer = new Cesium.Viewer("cesiumContainer", englishViewerConfig);



            }
            // 显示地图阴影
            // window.viewer.shadows= true;
            // window.viewer.scene.globe.enableLighting = true; // 开启光照
            // 显示帧率组件
            window.viewer.scene.debugShowFramesPerSecond = true;

            //  TODO 优化性能
            // 抗锯齿
            window.viewer.scene.fxaa = true;
            window.viewer.scene.postProcessStages.fxaa.enabled=false;

            // 设置最大俯仰角，[-90,0]区间内，默认为-30，单位弧度

     window.viewer.scene.screenSpaceCameraController.zoomEventTypes = [
        Cesium.CameraEventType.RIGHT_DRAG,
        Cesium.CameraEventType.WHEEL,
        Cesium.CameraEventType.PINCH,
    ];
     window.viewer.scene.screenSpaceCameraController.tiltEventTypes = [
        Cesium.CameraEventType.MIDDLE_DRAG,
        Cesium.CameraEventType.PINCH,
        {
            eventType: Cesium.CameraEventType.LEFT_DRAG,
            modifier: Cesium.KeyboardEventModifier.CTRL,
        },
        {
            eventType: Cesium.CameraEventType.RIGHT_DRAG,
            modifier: Cesium.KeyboardEventModifier.CTRL,
        },
    ];



            // window.viewer.scene.requestRenderMode = true;   // 开启渲染模式 加速
            // window.viewer.scene.debugShowFramesPerSecond = true;
            // window.viewer.resolutionScale = 0.9; // 默认值为 1.0；

            // window.viewer.scene.msaaSamples = 4 // 抗锯齿
            // window.viewer.scene.postProcessStages.fxaa.enabled = true; // 开启抗锯齿
            // window.viewer.highDynamicRangeSupported && (window.viewer.scene.highDynamicRange = true); // 开启高动态范围渲染
            // // viewer.resolutionScale = window.devicePixelRatio; // 分辨率

            // Hides the stars
            window.viewer.scene.skyBox.show = false;
            // Explicitly render a new frame
            // window.viewer.scene.requestRender(); // 显示帧率组件

            //初始化地图位置为中国
            //获取访问ip地址 拿到经纬度
            this.userLat = window.sessionStorage.getItem('userLat') - 0;
            this.userLng = window.sessionStorage.getItem('userLng')- 0;
            this.userTimezone = window.sessionStorage.getItem('userTimezone')

            this.additionalData.push([{ deviceImei:'1234561',lng: this.userLng, lat: this.userLat, height: 1000, gpsTime: '2025-01-04 08:30:00' },
                { deviceImei:'1234561',lng: 119.0, lat: 29.0, height: 1000, gpsTime: '2025-01-14 09:30:00' },
                    { deviceImei:'1234561',lng: 120.0, lat: 30.0, height: 1000, gpsTime: '2025-01-14 10:30:05' },
                    { deviceImei:'1234561',lng: 122.0, lat: 33.0, height: 2000, gpsTime: '2025-01-14 11:30:10' },
                    { deviceImei:'1234561',lng: 122.4, lat: 33.6, height: 3000, gpsTime: '2025-01-14 12:30:15' },
                    { deviceImei:'1234561',lng: 122.9, lat: 33.9, height: 4000, gpsTime: '2025-01-14 13:30:20' },
                    { deviceImei:'1234561',lng: 122.9, lat: 33.9, height: 4000, gpsTime: '2025-01-14 23:59:59' },
                    // ... 可能有更多数据
                ]);

                // 第二个数据

                this.additionalData.push([{ deviceImei:'1234562',lng: this.userLng, lat: this.userLat, height: 1000, gpsTime: '2025-01-04 08:30:00' },
                
                { deviceImei:'1234562',lng: 118.0, lat: 29.0, height: 1000, gpsTime: '2025-01-14 09:30:00' },
                    { deviceImei:'1234562',lng: 119.0, lat: 30.0, height: 1000, gpsTime: '2025-01-14 10:30:05' },
                    { deviceImei:'1234562',lng: 120.0, lat: 33.0, height: 2000, gpsTime: '2025-01-14 11:30:10' },
                    { deviceImei:'1234562',lng: 121.4, lat: 33.6, height: 3000, gpsTime: '2025-01-14 12:30:15' },
                    { deviceImei:'1234562',lng: 122.9, lat: 33.9, height: 4000, gpsTime: '2025-01-14 13:30:20' },
                    { deviceImei:'1234562',lng: 122.19, lat: 33.9, height: 4000, gpsTime: '2025-01-14 23:59:59' },
                    // ... 可能有更多数据
                ]);


            console.log("鸽子数据是", this.additionalData);
            console.log("拿到经纬度用户纬度 latitude", this.userLat);
            console.log("用户纬度 longitude", this.userLng);
            console.log("用户时区", this.userTimezone);


            window.viewer.camera.setView({
                // Cesium的坐标是以地心为原点，一向指向南美洲，一向指向亚洲，一向指向北极州
                // fromDegrees()方法，将经纬度和高程转换为世界坐标
                destination: Cesium.Cartesian3.fromDegrees(this.userLng, this.userLat, 8000000),
                orientation: {
                    // // 指向
                    // heading: 6.283185307179581,
                    // // 视角
                    // pitch: -1.5688168484696687,
                    // roll: 0.0
                    heading: Cesium.Math.toRadians(0.0),	// 以弧度为单位的航向角。
                    pitch: -Math.PI / 2,					// 以弧度为单位的俯仰角。
                    range: 10								// 到中心的距离，以米为单位。		
                }
            });


            window.viewer.homeButton.viewModel.command.beforeExecute.addEventListener((e) => {
                e.cancel = true;
                // 你要飞的位置
                window.viewer.camera.flyTo({
                    destination: Cesium.Cartesian3.fromDegrees(this.userLng, this.userLat, 50000.0), //500000
                    duration: 1, // 以秒为单位的飞行持续时间。
                    offset: {
                        heading: Cesium.Math.toRadians(0.0),	// 以弧度为单位的航向角。
                        pitch: -Math.PI / 2,					// 以弧度为单位的俯仰角。
                        range: 10								// 到中心的距离，以米为单位。								
                    }
                });
            });

            this.modelAnimate(this.additionalData,'./mapGLB/Cesium_Air.glb');

            // // 创建用于显示轨迹的实体
            // let data = [{ longitude: this.userLng, latitude: this.userLat, height: 1000 }]
            // this.updateTrajectory(data);
            // window.entity = window.viewer.entities.add({
            //   name: 'Device Trajectory',
            //   position: Cesium.Cartesian3.fromDegrees(this.userLng, this.userLat, 500.0),
            //   point: {
            //     pixelSize: 10,
            //     color: Cesium.Color.RED,
            //   },
            //   model: {
            //         show: true,
            //         uri: './mapGLB/Cesium_Air.glb', // 可以根据index加载不同的模型，例如：modelGltfArray[index]
            //         // scale: 1, // 
            //         minimumPixelSize: 128,  // 最小的模型像素
            //         maximumScale: 2000,
            //         //   runAnimations: false,//是否显示动画
            //         // clampAnimations: true,//是否保持最后一针的动画

            //     },
            //   path: {
            //     resolution: 2,
            //     material: new Cesium.PolylineOutlineMaterialProperty({
            //       color: Cesium.Color.RED.withAlpha(0.5),
            //       outlineWidth: 2,
            //       outlineColor: Cesium.Color.BLACK,
            //     }),
            //     width: 2,
            //   },
            // });

            // 加载OSM在线地图（标准风格）
            // window.viewer.imageryLayers.addImageryProvider(
            //     new Cesium.UrlTemplateImageryProvider({
            //         url: 'https://tile-{s}.openstreetmap.fr/hot/{z}/{x}/{y}.png',
            //         subdomains: ["a", "b", "c", "d"],
            //     })
            // );

            // 添加天地图影像注记底图 注册开发者，设置域名白名单 
            // window.viewer.imageryLayers.addImageryProvider(new Cesium.WebMapTileServiceImageryProvider({
            //     url: "http://t0.tianditu.gov.cn/cia_w/wmts?tk=f180b8af3efcf798a9281dd0f0d3dcae",
            //     layer: "cia",
            //     style: "default",
            //     tileMatrixSetID: "w",
            //     format: "tiles",
            //     maximumLevel: 18
            // }))


            // 如果需要叠加高德/百度注记地图则添加以下代码
            // window.viewer.imageryLayers.addImageryProvider(new Cesium.UrlTemplateImageryProvider({
            //     url: "http://webst02.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scale=1&style=8",
            //     layer: "tdtAnnoLayer",
            //     style: "default",
            //     format: "image/jpeg",
            //     tileMatrixSetID: "GoogleMapsCompatible"
            // }))

            // 自定义的近地天空盒 近景天空
            let groundSkybox = new Cesium.SkyBox({
                sources: {
                    negativeX: require('@/assets/skyBox/lantian/Left.jpg'),
                    positiveX: require('@/assets/skyBox/lantian/Right.jpg'),
                    negativeY: require('@/assets/skyBox/lantian/Back.jpg'),
                    positiveY: require('@/assets/skyBox/lantian/Front.jpg'),
                    negativeZ: require('@/assets/skyBox/lantian/Down.jpg'),
                    positiveZ: require('@/assets/skyBox/lantian/Up.jpg')
                }
            })

            // 自带的默认天空盒
            // let defaultSkybox = window.viewer.scene.skyBox;
            // 自定义的近地天空盒 远处 天空
            let defaultSkybox = new Cesium.SkyBox({
                sources: {
                    negativeX: require('@/assets/skyBox/5/tycho2t3_80_mx.jpg'),
                    positiveX: require('@/assets/skyBox/5/tycho2t3_80_px.jpg'),
                    negativeY: require('@/assets/skyBox/5/tycho2t3_80_my.jpg'),
                    positiveY: require('@/assets/skyBox/5/tycho2t3_80_py.jpg'),
                    negativeZ: require('@/assets/skyBox/5/tycho2t3_80_mz.jpg'),
                    positiveZ: require('@/assets/skyBox/5/tycho2t3_80_pz.jpg')
                }
            })
            // 渲染前监听并判断相机位置
            window.viewer.scene.preUpdate.addEventListener(() => {
                let position = window.viewer.scene.camera.position;
                let cameraHeight = Cesium.Cartographic.fromCartesian(position).height;
                if (cameraHeight < 240000) {
                    window.viewer.scene.skyBox = groundSkybox;
                    window.viewer.scene.skyAtmosphere.show = false;
                } else {
                    window.viewer.scene.skyBox = defaultSkybox;
                    window.viewer.scene.skyAtmosphere.show = true;
                }
            })






            // 立即设置时间格式化函数
            window.viewer.animation.viewModel.dateFormatter = this.CesiumDateFormatter;
            window.viewer.animation.viewModel.timeFormatter = this.CesiumTimeFormatter;
            Cesium.Timeline.prototype.makeLabel = this.CesiumDateTimeFormatter;




            // // 设置始时钟始时间
            window.viewer.clock.startTime = this.startTime.clone();
            window.viewer.clock.stopTime = this.stopTime.clone();


            window.viewer.clock.currentTime = this.currentTime.clone(); // 设置时钟当前时间
            // 时间速率，数字越大时间过的越快
            window.viewer.clock.multiplier = 1;

            // 假设start已经在之前定义过了，否则你需要从firstProperty中获取startTime
            // var start = times[0]; // 如果需要从第一个轨迹中获取开始时间的话

            // 时间轴（使用第一个轨迹的开始和停止时间）
            window.viewer.timeline.zoomTo(this.startTime, this.stopTime);

            // 循环执行，到达终止时间，重新从起点时间开始
            window.viewer.clock.clockRange = Cesium.ClockRange.LOOP_STOP;



            //TODO 调用绘制广告牌
            //  this.createIntersectionBillboards(this.startLng, this.startLat,this.endLng,  this.endLat,this.endLng,  this.endLat,circleRadius_30);


            // 确保时间轴更新
            // window.viewer.timeline.updateFromClock();
            // window.viewer.timeline.zoomTo(window.viewer.clock.startTime, window.viewer.clock.stopTime);

            // // 最小缩放高度（米）
            // window.viewer.scene.screenSpaceCameraController.minimumZoomDistance = 500;
            // // 最大缩放高度（米）
            // window.viewer.scene.screenSpaceCameraController.maximumZoomDistance = 500000;

            // 鼠标滚轮控制视角
            window.viewer.scene.screenSpaceCameraController.tiltEventTypes = [Cesium.CameraEventType.RIGHT_DRAG];

        },



        // cesium时钟时间格式化函数
        CesiumTimeFormatter(datetime, viewModel) {
            var julianDT = new Cesium.JulianDate();
            Cesium.JulianDate.addHours(datetime, 8, julianDT);
            var gregorianDT = Cesium.JulianDate.toGregorianDate(julianDT);
            let hour = gregorianDT.hour + "";
            let minute = gregorianDT.minute + "";
            let second = gregorianDT.second + "";
            return `${hour.padStart(2, "0")}:${minute.padStart(2, "0")} : ${second.padStart(2, "0")}`;
        },

        // cesium时钟日期格式化函数 年月日 改成 - - -
        CesiumDateFormatter(datetime, viewModel, ignoredate) {
            var julianDT = new Cesium.JulianDate();
            Cesium.JulianDate.addHours(datetime, 8, julianDT);
            var gregorianDT = Cesium.JulianDate.toGregorianDate(julianDT);
            return `${gregorianDT.year}-${gregorianDT.month}-${gregorianDT.day}`;
        },

        // cesium时间轴格式化函数 年月日时分
        CesiumDateTimeFormatter(datetime, viewModel, ignoredate) {
            var julianDT = new Cesium.JulianDate();
            Cesium.JulianDate.addHours(datetime, 8, julianDT);
            var gregorianDT = Cesium.JulianDate.toGregorianDate(julianDT);
            let hour = gregorianDT.hour + "";
            let minute = gregorianDT.minute + "";
            return `${gregorianDT.year}-${gregorianDT.month}-${gregorianDT.day}-${hour.padStart(2, "0")}:${minute.padStart(2, "0")}`;
        },

        // cesium时间轴格式化函数 年月日时分秒
        CesiumDateTimeXFormatter(datetime, viewModel, ignoredate) {

            var julianDT = new Cesium.JulianDate();
            Cesium.JulianDate.addHours(datetime, 8, julianDT);
            var gregorianDT = Cesium.JulianDate.toGregorianDate(julianDT);
            let hour = gregorianDT.hour + "";
            let minute = gregorianDT.minute + "";
            let second = gregorianDT.second + "";
            return `${gregorianDT.year}-${gregorianDT.month}-${gregorianDT.day}-${hour.padStart(2, "0")}:${minute.padStart(2, "0")}:${second.padStart(2, "0")}`;
        },






    }

}
</script>

<style lang="less" scoped>
// @import "../../.././public/Cesium/Widgets/widgets.css";

//  @import "../../../node_modules/cesium/Build/Cesium/Widgets/widgets.css";


#container {

    width: 100%;
    height: 100%;
}

::v-deep {

    // 全屏
    .cesium-viewer-fullscreenContainer {
        position: absolute;
        /* bottom: 0; */
        top: 8px;
        right: 48px;
        padding: 0;
        width: 29px;
        height: 29px;
        overflow: hidden;
    }

    // 改变时间进度条默认样式   !important 优先级 这里设置的优先
    #cesiumContainer .cesium-viewer .cesium-viewer-timelineContainer {
        right: 0px !important;
        left: 169px !important;

    }


    // 3D时间控件在2d地图上面
    #cesiumContainer .cesium-viewer-animationContainer {
        z-index: 2;
        width: 169px !important;
        height: 112px !important;
    }


}





//   刷新时间
.timeClass {
    font-weight: bold;
    color: black;
    background: #dddddd;
    position: absolute;
    text-align: center;
    z-index: 8;
    border-radius: 5px;
    left: 10%;
    top: 2.5%;
}
</style>