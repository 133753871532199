import request from '@/api/request' 

export function login (data) {
  const req = {
    action: 'userAction',
    method: 'login',
    data: {
      userName: data.userName,
      password: data.password
    }
  }
  return request({
    url: 'MainServlet',
    method: 'post',
    data: req
  })
}

export function deviceLogin (data) {
  const req = {
    action: 'deviceAction',
    method: 'login',
    data: {
      deviceImei: data.deviceImei,
      devicePassword: data.password
    }
  }
  return request({
    url: 'MainServlet',
    method: 'post',
    data: req
  })
}

export function experienceLogin () {
  const req = {
    action: 'userAction',
    method: 'login',
    data: {
      userName: 'experience',
      password: '111111q'
    }
  }
  return request({
    url: 'MainServlet',
    method: 'post',
    data: req
  })
}

export function listUser (data) {
  return request({
    url: 'user/list',
    method: 'post',
    data: data
  })
}

export function listRoleName (data) {
  const req = {
    customerId: data.customerId
  }
  return request({
    url: 'user/listRoleName',
    method: 'post',
    data: req
  })
}

export function createUser (data) {
  const user = {
    userName: data.userName,
    password: data.password,
    email: data.email,
    mobile: data.mobile,
    sex: data.sex,
    customerId: data.customerId,
    userRole: data.userRole,
    deptId: data.deptId
  }
  return request({
    url: 'user/create',
    method: 'post',
    data: user
  })
}

export function updateUser (data) {
  const user = {
    userId: data.userId,
    username: data.username,
    password: data.password,
    email: data.email,
    mobile: data.mobile,
    sex: data.sex,
    status: data.status,
    customerId: data.customerId
  }
  return request({
    url: 'user/update',
    method: 'post',
    data: user
  })
}

export function deleteUser (id) {
  const delData = {
    userIds: id
  }
  return request({
    url: 'user/delete',
    method: 'post',
    data: delData
  })
}

export function getUserById (userId) {
  return request({
    url: 'user/getUserById/' + userId,
    method: 'get'
  })
}

export function changePassword (data) {
  const req = {
    userId: data.userId,
    oldPassword: data.oldPassword,
    newPassword: data.newPassword
  }
  return request({
    url: 'user/changePassword',
    method: 'post',
    data: req
  })
}
