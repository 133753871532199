import axios from 'axios'

// axios.defaults.timeout = 180000
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

const service = axios.create({
  // axios中请求配置有baseURL选项,表示请求URL公共部分
  // 之前的
  // baseURL: 'http://trackservice.gps866.com',
  // TODO 大鸽大  http://bigpigeonservice.gps866.com:8078
  baseURL: 'http://bigpigeonservice.gps866.com:8078',
  // baseURL: 'http://track-api.gps866.com/webapi',
  // baseURL: 'http://localhost:8000/webapi',
  // 超时
  timeout: 180000
})

service.interceptors.request.use(config => {
  // 是否需要设置 token 
  const token = window.sessionStorage.getItem('token') || 'aT@87736339'
  config.headers['token'] = token // 让每个请求携带自定义token 请根据实际情况自行修改
  const userId = window.sessionStorage.getItem('userId') || 0
  config.headers['userId'] = userId
  config.headers['clientFlag'] = 1
  let session = window.sessionStorage.getItem('session') || ''
  if (session !== undefined) {
    config.headers['session'] = session
  }
  // TODO http://gps.gps866.com 之前的
  if (config.requestBase === 'http://bigpigeon.gps866.com:8078') {
    config.baseURL = 'http://bigpigeon.gps866.com:8078'
  }
  return config
},
service.interceptors.response.use(response => {
  // console.log(response.config.data)
  // console.log(response.data)
  return response
}),
error => {
  Promise.reject(error)
})

export default service
